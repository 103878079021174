.document-wrapper {
  align-items: center;
  border-bottom: 1px dotted var(--color-gray-lighter);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1px;
  padding-top: 4px;
  position: relative;
  min-height: var(--wcag-aa-min-size);

  &:hover {
    background: var(--color-gray-lighter);
  }

  &.document-upload-disabled {
    cursor: initial;

    &:hover {
      background: transparent;
    }
  }

  .document-identification {
    flex-grow: 1;
    min-width: 0;
    padding-right: 2px;
  }

  .document-type[data-is-document-loaded="true"] {
    font-weight: bold;
  }

  .document-name {
    color: var(--color-gray-darkest);
    font-size: 1.2rem;
  }

  .document-controls {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .document-delete-confirmation {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

.documents .content {
  .panel-inner {
    margin-top: 0;
  }

  .modal-body>*:first-child,
  .panel-body {
    margin-bottom: var(--card-padding-axial);
  }
}

.document-modal {
  .modal {
    height: 100vh;
  }

  .modal-content {
    height: 90vh;
  }

  .modal-content iframe {
    height: 75vh;
  }
}
