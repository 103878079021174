.card {
  animation: card-enter 0.55s forwards;
  border: var(--card-border);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);
  background-color: var(--card-background);
  margin-bottom: var(--card-margin-bottom);

  &.disabled {

    .input-group,
    .form-control,
    .select__control,
    .input-group-addon,
    .checkbox {
      background: var(--muted-background-color);
      cursor: not-allowed;
      pointer-events: none;
    }

    .btn-group button {
      background: var(--muted-background-color);
      border-color: var(--muted-border-color);
      color: var(--input-placeholder-color);
      cursor: not-allowed;
      pointer-events: none;
    }

    .tile-btn {
      background: var(--muted-background-color);
      cursor: not-allowed;
      pointer-events: none;

      &.tile-btn-selected {
        background: var(--color-gray-lighter);
        border-color: var(--muted-border-color);
        color: var(--text-color) !important;
      }
    }

    .checkbox {
      color: var(--input-placeholder-color);
      padding: 7px 4px 0;
      margin: 0;
      min-height: 34px;
    }
  }

  &.no-card {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: unset;
    margin-bottom: 0;

    > {
      .content {
        padding: 0;
      }

      .header {
        background-color: transparent;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
      }

      .panel .content {
        padding: 0;
      }
    }
  }

  .header {
    border-radius: var(--card-border-radius);
    font-weight: 300;
    font-size: var(--font-size-h4);
    padding: var(--card-header-padding);

    .card-header-col {
      min-height: var(--card-header-col-min-height);
    }

    >.row>div {
      .btn {
        margin-bottom: 0;
        margin-top: 0;
        min-height: var(--card-header-btn-min-height);
      }

      .bootstrap-switch {
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    ~.panel .content,
    ~.content {
      padding-top: var(--card-header-plus-content-padding-top);
    }
  }

  .title {
    color: var(--card-header-text-color);
    font-size: var(--card-title-font-size);
    font-weight: var(--card-title-font-weight);
    line-height: var(--line-height);
    margin: 0;
    text-transform: var(--card-title-text-transform);
    word-break: break-word;

    .btn i {
      color: var(--card-header-text-color, inherit);
    }
  }

  .category {
    color: var(--card-header-text-color);
    font-size: var(--category-label-font-size);
    font-weight: 600;
    margin-bottom: 0;
  }

  .control-label {
    font-size: var(--form-group-label-font-size);
    margin-bottom: 2px;
  }

  .form-group>label {
    color: var(--text-color);
    font-size: var(--form-group-label-font-size);
    font-weight: 600;
    margin-bottom: 2px;
  }

  .content {
    padding: var(--card-content-padding);

    &:empty {
      padding: 0;
    }
  }

  .footer {
    padding: var(--card-footer-padding);
  }

  .ct-chart {
    clear: both;
    display: flex;
    flex-direction: column;
    min-height: 245px;

    >svg {
      flex-grow: 1;
      overflow: visible;
    }
  }

  .ct-chart-stats-card {
    height: 100px;
    margin: 0;
    min-height: unset;
  }

  .image {
    border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
    height: 260px;
    overflow: hidden;
    position: relative;
    transform-style: preserve-3d;
    width: 100%;

    img {
      width: 100%;
    }
  }

  .panel {
    border-bottom-left-radius: var(--card-border-radius);
    border-bottom-right-radius: var(--card-border-radius);
  }

  &.user-avatar {
    text-align: center;

    .user-photo {
      margin: 0 auto;
      position: relative;
      height: 110px;
      width: 110px;

      img,
      .user-default-avatar {
        object-fit: cover;
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid;
        height: 100%;
        position: relative;
        margin-bottom: var(--block-spacing);
        width: 100%;
      }

      .delete-user-photo {
        color: var(--color-gray);
        cursor: pointer;
        position: absolute;
        right: -4px;
      }
    }

    .user-default-avatar {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0 auto var(--block-spacing);
    }
  }
}

.navs-card {
  border-bottom: 1px solid var(--card-border-color);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--card-margin-bottom);

  .nav {
    align-items: center;
    border: 1px solid transparent;
    border-bottom: 0;
    border-radius: var(--border-radius-base);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: inline-flex;
    justify-content: center;
    padding: calc(var(--block-spacing) / 2) var(--block-spacing);

    &:not([disabled]) {
      cursor: pointer;
    }

    &:hover {
      background-color: var(--color-white);
    }

    .nav-icon {
      margin-right: 4px;
    }
  }

  [data-active-nav="true"] {
    background-color: var(--color-white);
    border-color: var(--card-border-color);
    font-weight: bold;
  }

  &[data-display-mode="list"] {
    animation: card-enter 0.55s forwards;
    border: var(--card-border);
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-box-shadow);
    background-color: var(--card-background);
    display: block;
    margin-bottom: var(--card-margin-bottom);
    overflow: hidden;

    .nav {
      align-items: center;
      border: 0;
      border-radius: 0;
      display: flex;
      justify-content: flex-start;
      width: 100%;

      &:focus,
      &:hover {
        .nav-label {
          text-decoration: underline;
        }
      }

      &[data-active-nav="true"] {
        background-color: var(--primary-color);
        color: var(--color-white);
        font-weight: normal;
      }
    }
  }
}

@keyframes card-enter {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// First case: when cards are wrapped in layout card that is not in no-card mode, except html cards.
// Second case : (rare case, for now only in PersonIndividual, PersonCompany and PersonProfessional components)
// a card has layout-cards in no-card mode that has cards in no-card mode too.
.person-global .layout-card.no-card:not(.hidden) {
  border-top: 1px solid var(--card-border-color);
}

.person-company .layout-card.no-card,
.person-individual .layout-card.no-card,
.person-professional .layout-card.no-card {
  border-top: 1px solid var(--color-gray);
}

// in pages always remove the top border
.page .layout-card>.content>.panel-inner::before {
  border-top: 0;
}

// to avoid double top border
.layout-card>.content:first-child>.panel-inner::before {
  border-top: 0;
}

// when the layout card has no title, the first card should not have a top border to avoid
// having it along collapse on the card's top border
.layout-card:not(.no-card) .content:first-child {
  padding-top: 0;

  .card:first-child {
    border-top: 0;
  }
}

.stats-card-link-wrapper:hover .stats-card .numbers {
  text-decoration: underline;
}

.stats-card {
  align-items: center;
  display: flex;

  .content {
    flex: 1;
    min-height: var(--stats-card-min-height);
    padding: calc(var(--card-padding-axial) / 2) var(--card-padding-lateral);
  }

  .numbers {
    text-align: right;

    .kpi-text {
      font-size: var(--kpi-text-font-size);
      margin: 0;
    }

    .kpi-value {
      font-size: var(--kpi-number-font-size);
      font-weight: 600;
    }
  }
}

.stats-card,
.card-html {
  .big-icon {
    align-items: center;
    display: flex;
    font-size: var(--kpi-icon-font-size);
    height: 100%;
    justify-content: center;

    i {
      font-weight: 500;

      &.text-primary {
        color: var(--primary-color) !important;
      }

      &.text-info {
        color: var(--color-blue) !important;
      }

      &.text-success {
        color: var(--color-green) !important;
      }

      &.text-warning {
        color: var(--color-orange) !important;
      }

      &.text-danger {
        color: var(--color-red) !important;
      }

      &.text-muted {
        color: var(--color-gray-darkest);
      }
    }
  }
}

.lazy-skeleton-ui {
  padding: var(--block-spacing);

  &[data-no-padding="true"] {
    padding: 0;
  }
}

.skeleton-placeholder-card {
  animation: skeleton-placeholder-animation 0.55s infinite alternate;
  background-color: var(--color-white);
  border: 0;
  min-height: var(--stats-card-min-height);
  overflow: hidden;
  position: relative;
}

.card.product-scheme-variables.no-card {
  position: relative;
  z-index: 1;
}

.product-scheme-elements.row {
  position: relative;
  z-index: 0 !important;
}

.asset-img-controls {
  flex-wrap: wrap;
}

.asset-img-controls-design-mode {
  .form-group {
    margin-bottom: 0;

    .btn-group {
      min-height: 0;
    }
  }
}

.asset-img-mosaic-grid {
  position: relative;
  padding: 0;
  margin-bottom: 16px;

  .asset-img-closer-look {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
    overflow: hidden;
    cursor: pointer;
    z-index: 5;

    .asset-img-closer-look-img {
      &.closer-look-contain {
        max-height: 100%;
        max-width: 100%;
      }

      &.closer-look-cover {
        min-height: 100%;
        min-width: 100%;
      }
    }
  }

  .scrolling-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: block;
    height: 440px;
  }

  .scrolling-item {
    display: inline-block;
    vertical-align: top;
    height: 100%;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .mosaic-img {
    width: 100%;
    aspect-ratio: 16 / 9;
    display: block;
    object-fit: cover;
    cursor: pointer;
    transition: filter 0.1s ease;
    overflow: hidden;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    img {
      height: 100%;
      object-fit: cover;
      aspect-ratio: 16 / 9;

      &.failed-to-fetch {
        height: 50%;
        width: auto;
        aspect-ratio: auto;
        inset: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
      }

      &.dragging {
        border: 2px solid var(--primary-color);
        /* highlight color for the dragged item */
      }

      &.drag-over {
        border: 2px dashed var(--primary-states-color);
        /* highlight color for the target item */
      }
    }

    .mosaic-img-checkbox {
      position: absolute;
      z-index: 3;
      top: 3%;
      left: 3%;

      >input[type="checkbox"] {
        height: 24px;
        width: 24px;
      }
    }
  }

  // I assume noone will ever want more than 8 rows in a mosaic, so we'll keep the compiled CSS small by limiting the loop to 8.
  // If one day, there are issues with more than 8 rows, we can increase the limit.
  @for $i from 1 through 8 {
    .mosaic-img.hdiv-#{$i} {
      height: calc((100% / #{$i}) - 8px);
      min-height: auto;
    }
  }
}

@keyframes skeleton-placeholder-animation {
  0% {
    opacity: 0.45;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: $screen-xs) {
  .stats-card {
    .content>.row {
      flex-direction: column;

      >[class*="col-"] {
        padding: 0;
        width: 100%;

        .big-icon {
          margin: 0 auto;
        }

        .numbers {
          font-size: 2.4rem;
          text-align: center;

          p {
            font-size: var(--font-size-base);
          }
        }
      }
    }
  }

  .navs-card {
    border-bottom: 0;

    .nav {
      border-bottom: 1px solid transparent;
      border-bottom-left-radius: var(--border-radius-base);
      border-bottom-right-radius: var(--border-radius-base);
      width: 50%;
    }

    [data-active-nav="true"] {
      border-bottom-color: var(--card-border-color);
    }
  }
}

@media (min-width: $screen-sm) {
  .agency-details {
    padding-left: 20px;
  }
}

// In mobile we don't want to show the stepper because
// it is too much effort to render something correct.
// To avoid having to override these rules, exceptionnally they are
// written in a min-width block (remember that this should be avoided most of the time).
@media (min-width: $screen-sm) {
  .navs-card[data-display-mode="stepper"] {
    border-bottom: 0;
    justify-content: center;

    .nav {
      border: 0;
      color: var(--primary-color);
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
      width: var(--step-width);

      .nav-wrapper {
        flex-direction: column;
      }

      &:hover {
        background-color: transparent;
      }

      &::after {
        background-color: var(--primary-color);
        content: "";
        height: 1px;
        left: 0;
        position: relative;
        top: -30px;
        width: 100%;
      }

      &:first-child,
      &:last-child {
        &::after {
          left: calc(var(--step-width) / 4);
          width: calc(var(--step-width) / 2);
        }
      }

      &:last-child {
        &::after {
          left: calc(-1 * var(--step-width) / 4);
        }
      }

      &[disabled] {

        .nav-icon i,
        .nav-label {
          opacity: 0.25;
        }
      }

      i {
        align-items: center;
        display: inline-flex;
        justify-content: center;
        margin-right: 0;
      }

      .nav-icon {
        background: var(--body-background);
        position: relative;
        z-index: 1;
      }

      .nav-icon i,
      .nav-label {
        opacity: 0.6;
      }
    }

    [data-active-nav="true"] {
      background-color: transparent;
      border: 0;
      color: var(--text-color);

      i {
        color: var(--primary-color);
      }

      .nav-icon i,
      .nav-label {
        opacity: 1;
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .agency-details {
    padding-top: 20px;
  }
}

.card-collapse-col {
  display: none;
}

@media (max-width: $screen-xs) {
  .card-collapse-col {
    display: block;
  }

  .card-collapse-action {
    display: none !important;
  }
}