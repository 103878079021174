/*
 * Taken from Bootstrap v3.3.7 (http://getbootstrap.com) and heavily modified.
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

article,
aside,
details,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

figure {
  display: block;
  margin: 1em 40px;
  margin: 0;
}

audio {
  display: inline-block;
  vertical-align: baseline;

  &:not([controls]) {
    display: none;
    height: 0;
  }
}

canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

[hidden] {
  display: none;
}

template {
  display: none;
}

a {
  background-color: transparent;
  text-decoration: none;

  &:active,
  &:hover {
    outline: 0;
    text-decoration: underline;
  }

  &:focus {
    outline: var(--accessibility-outline);
    outline-offset: var(--accessibility-outline-offset);
  }
}

abbr[title] {
  border-bottom: 1px dotted;
  cursor: help;
  border-bottom: 1px dotted #777;
}

b {
  font-weight: bold;
}

strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: 0.67em 0;
  font-size: 2em;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 3.6rem;

  small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 65%;
  }

  .small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 65%;
  }
}

mark {
  color: #000;
  background: #ff0;
  padding: 0.2em;
  background-color: #fcf8e3;
}

small {
  font-size: 80%;
  font-size: 85%;
}

sub {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
  bottom: -0.25em;
}

sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
  top: -0.5em;
}

img {
  border: 0;
  vertical-align: middle;
}

svg {
  &:not(:root) {
    overflow: hidden;
  }
}

hr {
  height: 0;
  box-sizing: content-box;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

pre {
  overflow: auto;
  font-size: 1em;
  font-family: Consolas, "Courier New", monospace;
  display: block;
  padding: 7px;
  margin: 0 0 10px;
  font-size: var(--font-size-base);
  line-height: var(--line-height);
  color: var(--color-black);
  word-break: break-all;
  word-wrap: break-word;
  background-color: var(--color-gray-lightest);
  border: 1px solid var(--card-border-color);
  border-radius: 4px;

  code {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: inherit;
    padding: 0;
    white-space: pre-wrap;
  }
}

code {
  background-color: #fff0b5;
  border: 1px solid var(--color-yellow);
  border-radius: var(--border-radius-base);
  color: var(--color-black);
  font-family: Consolas, "Courier New", monospace;
  font-size: 90%;
  padding: 2px 4px;
  word-break: break-word;
}

kbd {
  font-size: 1em;
  font-family: Consolas, "Courier New", monospace;
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);

  kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold;
    box-shadow: none;
  }
}

samp {
  font-size: 1em;
  font-family: Consolas, "Courier New", monospace;
}

button {
  margin: 0;
  font: inherit;
  color: inherit;
  overflow: visible;
  text-transform: none;
  appearance: button;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}

input {
  margin: 0;
  font: inherit;
  color: inherit;
  line-height: normal;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}

optgroup {
  margin: 0;
  font: inherit;
  color: inherit;
  font-weight: bold;
}

select {
  margin: 0;
  font: inherit;
  color: inherit;
  text-transform: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

textarea {
  margin: 0;
  font: inherit;
  color: inherit;
  overflow: hidden;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input[type="reset"] {
  appearance: button;
  cursor: pointer;
}

input[type="submit"] {
  appearance: button;
  cursor: pointer;
}

button[disabled] {
  cursor: default;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  line-height: normal;
}

input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;

  &:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
}

input[type="number"] {
  &::-webkit-inner-spin-button {
    height: auto;
  }

  &::-webkit-outer-spin-button {
    height: auto;
  }
}

input[type="search"] {
  box-sizing: content-box;
  appearance: textfield;
  box-sizing: border-box;
  appearance: none;

  &::-webkit-search-decoration {
    appearance: none;
  }
}

fieldset {
  padding: 0.35em 0.625em 0.75em;
  margin: 0 2px;
  border: 1px solid #c0c0c0;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  padding: 0;
  border: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 2.1rem;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  background-color: transparent;

  col[class*="col-"] {
    position: static;
    display: table-column;
    float: none;
  }

  td[class*="col-"] {
    position: static;
    display: table-cell;
    float: none;
  }

  th[class*="col-"] {
    position: static;
    display: table-cell;
    float: none;
  }
}

td {
  padding: 0;
}

th {
  padding: 0;
  text-align: left;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.thumbnail {
  >img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  }

  a {
    >img {
      display: block;
      max-width: 100%;
      height: auto;
      margin-right: auto;
      margin-left: auto;
    }
  }

  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: var(--line-height);
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border 0.2s ease-in-out;

  .caption {
    padding: 9px;
    color: #333;
  }
}

.img-rounded {
  border-radius: 6px;
}

.img-thumbnail {
  display: inline-block;
  max-width: 100%;
  height: auto;
  padding: 4px;
  line-height: var(--line-height);
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.img-circle {
  border-radius: 50%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable {
  &:active {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }

  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

[role="button"] {
  cursor: pointer;
}

h2 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 3rem;

  small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 65%;
  }

  .small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 65%;
  }
}

h3 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 2.4rem;

  small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 65%;
  }

  .small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 65%;
  }
}

h4 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.8rem;

  small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 75%;
  }

  .small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 75%;
  }
}

h5 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: var(--font-size-base);

  small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 75%;
  }

  .small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 75%;
  }
}

h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.2rem;

  small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 75%;
  }

  .small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 75%;
  }
}

.h1 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 3.6rem;

  small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 65%;
  }

  .small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 65%;
  }
}

.h2 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 3rem;

  small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 65%;
  }

  .small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 65%;
  }
}

.h3 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 2.4rem;

  small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 65%;
  }

  .small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 65%;
  }
}

.h4 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.8rem;

  small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 75%;
  }

  .small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 75%;
  }
}

.h5 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: var(--font-size-base);

  small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 75%;
  }

  .small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 75%;
  }
}

.h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.2rem;

  small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 75%;
  }

  .small {
    font-weight: normal;
    line-height: 1;
    color: #777;
    font-size: 75%;
  }
}

p {
  margin: 0 0 var(--block-spacing);
  word-wrap: break-word;

  &:last-child {
    margin-bottom: 0;
  }
}

.lead {
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.4;
}

.small {
  font-size: 85%;
}

.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-muted {
  color: #777;
}

.text-primary {
  color: #337ab7;
}

.text-dark {
  color: #343a40 !important;
}

a.text-primary {
  &:hover {
    color: #286090;
  }

  &:focus {
    color: #286090;
  }
}

.text-success {
  color: #3c763d;
}

a.text-success {
  &:hover {
    color: #2b542c;
  }

  &:focus {
    color: #2b542c;
  }
}

.text-info {
  color: #31708f;
}

a.text-info {
  &:hover {
    color: #245269;
  }

  &:focus {
    color: #245269;
  }
}

.text-warning {
  color: #8a6d3b;
}

a.text-warning {
  &:hover {
    color: #66512c;
  }

  &:focus {
    color: #66512c;
  }
}

.text-danger {
  color: #a94442;
}

a.text-danger {
  &:hover {
    color: #843534;
  }

  &:focus {
    color: #843534;
  }
}

.bg-primary {
  color: #fff;
  background-color: #337ab7;
}

a.bg-primary {
  &:hover {
    background-color: #286090;
  }

  &:focus {
    background-color: #286090;
  }
}

.bg-success {
  background-color: #dff0d8;
}

a.bg-success {
  &:hover {
    background-color: #c1e2b3;
  }

  &:focus {
    background-color: #c1e2b3;
  }
}

.bg-info {
  background-color: #d9edf7;
}

a.bg-info {
  &:hover {
    background-color: #afd9ee;
  }

  &:focus {
    background-color: #afd9ee;
  }
}

.bg-warning {
  background-color: #fcf8e3;
}

a.bg-warning {
  &:hover {
    background-color: #f7ecb5;
  }

  &:focus {
    background-color: #f7ecb5;
  }
}

.bg-danger {
  background-color: #f2dede;
}

a.bg-danger {
  &:hover {
    background-color: #e4b9b9;
  }

  &:focus {
    background-color: #e4b9b9;
  }
}

.page-header {
  border-bottom: 1px solid var(--card-border-color);
  margin: calc(var(--block-spacing) * 2) 0 var(--block-spacing);
  padding-bottom: var(--block-spacing);
}

ul {
  margin-top: 0;
  margin-bottom: 10px;

  ul {
    margin-bottom: 0;
  }

  ol {
    margin-bottom: 0;
  }
}

ol {
  margin-top: 0;
  margin-bottom: 10px;

  ul {
    margin-bottom: 0;
  }

  ol {
    margin-bottom: 0;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;

  >li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
  }
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}

dt {
  line-height: var(--line-height);
  font-weight: bold;
}

dd {
  line-height: var(--line-height);
  margin-left: 0;
}

abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #777;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 1.75rem;
  border-left: 5px solid #eee;

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    &:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }

  footer {
    display: block;
    font-size: 80%;
    line-height: var(--line-height);
    color: #777;

    &:before {
      content: "\2014 \00A0";
    }
  }

  small {
    display: block;
    font-size: 80%;
    line-height: var(--line-height);
    color: #777;

    &:before {
      content: "\2014 \00A0";
    }
  }

  .small {
    display: block;
    font-size: 80%;
    line-height: var(--line-height);
    color: #777;

    &:before {
      content: "\2014 \00A0";
    }
  }
}

.blockquote-reverse {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid #eee;
  border-left: 0;

  footer {
    &:before {
      content: "";
    }

    &:after {
      content: "\00A0 \2014";
    }
  }

  small {
    &:before {
      content: "";
    }

    &:after {
      content: "\00A0 \2014";
    }
  }

  .small {
    &:before {
      content: "";
    }

    &:after {
      content: "\00A0 \2014";
    }
  }
}

blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid #eee;
  border-left: 0;

  footer {
    &:before {
      content: "";
    }

    &:after {
      content: "\00A0 \2014";
    }
  }

  small {
    &:before {
      content: "";
    }

    &:after {
      content: "\00A0 \2014";
    }
  }

  .small {
    &:before {
      content: "";
    }

    &:after {
      content: "\00A0 \2014";
    }
  }
}

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: var(--line-height);
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  >.navbar-header {
    margin-right: -15px;
    margin-left: -15px;
  }

  >.navbar-collapse {
    margin-right: -15px;
    margin-left: -15px;
  }

  .jumbotron {
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 6px;
  }

  &:before {
    display: table;
    content: " ";
  }

  &:after {
    display: table;
    content: " ";
    clear: both;
  }
}

.row {
  margin-right: -15px;
  margin-left: -15px;

  &:before {
    display: table;
    content: " ";
  }

  &:after {
    display: table;
    content: " ";
    clear: both;
  }

  &:empty {
    display: none;
  }
}

.col-xs-1 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 8.33333333%;
}

.col-sm-1 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-1 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg-1 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-2 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 16.66666667%;
}

.col-sm-2 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-2 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg-2 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-3 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 25%;
}

.col-sm-3 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-3 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg-3 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-4 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 33.33333333%;
}

.col-sm-4 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-4 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg-4 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-5 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 41.66666667%;
}

.col-sm-5 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-5 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg-5 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-6 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 50%;
}

.col-sm-6 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-6 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg-6 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-7 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 58.33333333%;
}

.col-sm-7 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-7 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg-7 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-8 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 66.66666667%;
}

.col-sm-8 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-8 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg-8 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 75%;
}

.col-sm-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-10 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 83.33333333%;
}

.col-sm-10 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-10 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg-10 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-11 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 91.66666667%;
}

.col-sm-11 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-11 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg-11 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;
  width: 100%;
}

.col-sm-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left;
}

.table {
  margin-bottom: 1px;
  max-width: 100%;
  width: 100%;

  >thead {
    >tr {
      >th {
        padding: 8px;
        line-height: var(--line-height);
        vertical-align: top;
        border-top: 1px solid #ddd;
        vertical-align: bottom;
        border-bottom: 2px solid #ddd;
      }

      >td {
        padding: 8px;
        line-height: var(--line-height);
        vertical-align: top;
        border-top: 1px solid #ddd;
      }

      >td.active {
        background-color: #f5f5f5;
      }

      >th.active {
        background-color: #f5f5f5;
      }

      >td.success {
        background-color: #dff0d8;
      }

      >th.success {
        background-color: #dff0d8;
      }

      >td.info {
        background-color: #d9edf7;
      }

      >th.info {
        background-color: #d9edf7;
      }

      >td.warning {
        background-color: #fcf8e3;
      }

      >th.warning {
        background-color: #fcf8e3;
      }

      >td.danger {
        background-color: #f2dede;
      }

      >th.danger {
        background-color: #f2dede;
      }
    }

    &:first-child {
      >tr {
        &:first-child {
          >th {
            border-top: 0;
          }

          >td {
            border-top: 0;
          }
        }
      }
    }

    >tr.active {
      >td {
        background-color: #f5f5f5;
      }

      >th {
        background-color: #f5f5f5;
      }
    }

    >tr.success {
      >td {
        background-color: #dff0d8;
      }

      >th {
        background-color: #dff0d8;
      }
    }

    >tr.info {
      >td {
        background-color: #d9edf7;
      }

      >th {
        background-color: #d9edf7;
      }
    }

    >tr.warning {
      >td {
        background-color: #fcf8e3;
      }

      >th {
        background-color: #fcf8e3;
      }
    }

    >tr.danger {
      >td {
        background-color: #f2dede;
      }

      >th {
        background-color: #f2dede;
      }
    }
  }

  >tbody {
    >tr {
      >th {
        padding: 8px;
        line-height: var(--line-height);
        vertical-align: top;
        border-top: 1px solid #ddd;
      }

      >td {
        padding: 8px;
        line-height: var(--line-height);
        vertical-align: top;
        border-top: 1px solid #ddd;
      }

      >td.active {
        background-color: #f5f5f5;
      }

      >th.active {
        background-color: #f5f5f5;
      }

      >td.success {
        background-color: #dff0d8;
      }

      >th.success {
        background-color: #dff0d8;
      }

      >td.info {
        background-color: #d9edf7;
      }

      >th.info {
        background-color: #d9edf7;
      }

      >td.warning {
        background-color: #fcf8e3;
      }

      >th.warning {
        background-color: #fcf8e3;
      }

      >td.danger {
        background-color: #f2dede;
      }

      >th.danger {
        background-color: #f2dede;
      }
    }

    + {
      tbody {
        border-top: 2px solid #ddd;
      }
    }

    >tr.active {
      >td {
        background-color: #f5f5f5;
      }

      >th {
        background-color: #f5f5f5;
      }
    }

    >tr.success {
      >td {
        background-color: #dff0d8;
      }

      >th {
        background-color: #dff0d8;
      }
    }

    >tr.info {
      >td {
        background-color: #d9edf7;
      }

      >th {
        background-color: #d9edf7;
      }
    }

    >tr.warning {
      >td {
        background-color: #fcf8e3;
      }

      >th {
        background-color: #fcf8e3;
      }
    }

    >tr.danger {
      >td {
        background-color: #f2dede;
      }

      >th {
        background-color: #f2dede;
      }
    }
  }

  >tfoot {
    >tr {
      >th {
        padding: 8px;
        line-height: var(--line-height);
        vertical-align: top;
        border-top: 1px solid #ddd;
      }

      >td {
        padding: 8px;
        line-height: var(--line-height);
        vertical-align: top;
        border-top: 1px solid #ddd;
      }

      >td.active {
        background-color: #f5f5f5;
      }

      >th.active {
        background-color: #f5f5f5;
      }

      >td.success {
        background-color: #dff0d8;
      }

      >th.success {
        background-color: #dff0d8;
      }

      >td.info {
        background-color: #d9edf7;
      }

      >th.info {
        background-color: #d9edf7;
      }

      >td.warning {
        background-color: #fcf8e3;
      }

      >th.warning {
        background-color: #fcf8e3;
      }

      >td.danger {
        background-color: #f2dede;
      }

      >th.danger {
        background-color: #f2dede;
      }
    }

    >tr.active {
      >td {
        background-color: #f5f5f5;
      }

      >th {
        background-color: #f5f5f5;
      }
    }

    >tr.success {
      >td {
        background-color: #dff0d8;
      }

      >th {
        background-color: #dff0d8;
      }
    }

    >tr.info {
      >td {
        background-color: #d9edf7;
      }

      >th {
        background-color: #d9edf7;
      }
    }

    >tr.warning {
      >td {
        background-color: #fcf8e3;
      }

      >th {
        background-color: #fcf8e3;
      }
    }

    >tr.danger {
      >td {
        background-color: #f2dede;
      }

      >th {
        background-color: #f2dede;
      }
    }
  }

  >caption {
    + {
      thead {
        >tr {
          &:first-child {
            >th {
              border-top: 0;
            }

            >td {
              border-top: 0;
            }
          }
        }
      }
    }
  }

  >colgroup {
    + {
      thead {
        >tr {
          &:first-child {
            >th {
              border-top: 0;
            }

            >td {
              border-top: 0;
            }
          }
        }
      }
    }
  }

  .table {
    background-color: #fff;
  }
}

.table-condensed {
  >thead {
    >tr {
      >th {
        padding: 5px;
      }

      >td {
        padding: 5px;
      }
    }
  }

  >tbody {
    >tr {
      >th {
        padding: 5px;
      }

      >td {
        padding: 5px;
      }
    }
  }

  >tfoot {
    >tr {
      >th {
        padding: 5px;
      }

      >td {
        padding: 5px;
      }
    }
  }
}

.table-bordered {
  border: 1px solid #ddd;

  >thead {
    >tr {
      >th {
        border: 1px solid #ddd;
        border-bottom-width: 2px;
      }

      >td {
        border: 1px solid #ddd;
        border-bottom-width: 2px;
      }
    }
  }

  >tbody {
    >tr {
      >th {
        border: 1px solid #ddd;
      }

      >td {
        border: 1px solid #ddd;
      }
    }
  }

  >tfoot {
    >tr {
      >th {
        border: 1px solid #ddd;
      }

      >td {
        border: 1px solid #ddd;
      }
    }
  }
}

.table-hover {
  >tbody {
    >tr {
      &:hover {
        background-color: #f5f5f5;

        >.active {
          background-color: #e8e8e8;
        }

        >.success {
          background-color: #d0e9c6;
        }

        >.info {
          background-color: #c4e3f3;
        }

        >.warning {
          background-color: #faf2cc;
        }

        >.danger {
          background-color: #ebcccc;
        }
      }

      >td.active {
        &:hover {
          background-color: #e8e8e8;
        }
      }

      >th.active {
        &:hover {
          background-color: #e8e8e8;
        }
      }

      >td.success {
        &:hover {
          background-color: #d0e9c6;
        }
      }

      >th.success {
        &:hover {
          background-color: #d0e9c6;
        }
      }

      >td.info {
        &:hover {
          background-color: #c4e3f3;
        }
      }

      >th.info {
        &:hover {
          background-color: #c4e3f3;
        }
      }

      >td.warning {
        &:hover {
          background-color: #faf2cc;
        }
      }

      >th.warning {
        &:hover {
          background-color: #faf2cc;
        }
      }

      >td.danger {
        &:hover {
          background-color: #ebcccc;
        }
      }

      >th.danger {
        &:hover {
          background-color: #ebcccc;
        }
      }
    }

    >tr.active {
      &:hover {
        >td {
          background-color: #e8e8e8;
        }

        >th {
          background-color: #e8e8e8;
        }
      }
    }

    >tr.success {
      &:hover {
        >td {
          background-color: #d0e9c6;
        }

        >th {
          background-color: #d0e9c6;
        }
      }
    }

    >tr.info {
      &:hover {
        >td {
          background-color: #c4e3f3;
        }

        >th {
          background-color: #c4e3f3;
        }
      }
    }

    >tr.warning {
      &:hover {
        >td {
          background-color: #faf2cc;
        }

        >th {
          background-color: #faf2cc;
        }
      }
    }

    >tr.danger {
      &:hover {
        >td {
          background-color: #ebcccc;
        }

        >th {
          background-color: #ebcccc;
        }
      }
    }
  }
}

.table-wrapper {
  min-height: 0.01%;
  overflow-x: auto;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="file"] {
  display: block;

  &:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
}

input[type="range"] {
  display: block;
  width: 100%;
}

select[multiple] {
  height: auto;
}

select[size] {
  height: auto;
}

output {
  display: block;
  padding-top: 7px;
  font-size: var(--font-size-base);
  line-height: var(--line-height);
  color: #555;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: var(--font-size-base);
  line-height: var(--line-height);
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;

  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }

  &::-moz-placeholder {
    color: #999;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: #999;
  }

  &::-webkit-input-placeholder {
    color: #999;
  }

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }
}

.form-control[disabled] {
  background-color: var(--color-gray-lightest);
  opacity: 1;
}

.form-control[readonly] {
  background-color: var(--color-gray-lightest);
  opacity: 1;
}

fieldset[disabled] {
  .form-control {
    background-color: var(--color-gray-lightest);
    opacity: 1;
    cursor: not-allowed;
  }

  input[type="radio"] {
    cursor: not-allowed;
  }

  input[type="checkbox"] {
    cursor: not-allowed;
  }

  .radio-inline {
    cursor: not-allowed;
  }

  .checkbox-inline {
    cursor: not-allowed;
  }

  .radio {
    label {
      cursor: not-allowed;
    }
  }

  .checkbox {
    label {
      cursor: not-allowed;
    }
  }

  .btn {
    cursor: not-allowed;
    box-shadow: none;
  }

  a.btn {
    pointer-events: none;
  }

  .btn-default {
    &:hover {
      background-color: #fff;
      border-color: #ccc;
    }

    &:focus {
      background-color: #fff;
      border-color: #ccc;
    }
  }

  .btn-default.focus {
    background-color: #fff;
    border-color: #ccc;
  }

  .btn-primary {
    &:hover {
      background-color: #337ab7;
      border-color: #2e6da4;
    }

    &:focus {
      background-color: #337ab7;
      border-color: #2e6da4;
    }
  }

  .btn-primary.focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }

  .btn-success {
    &:hover {
      background-color: #5cb85c;
      border-color: #4cae4c;
    }

    &:focus {
      background-color: #5cb85c;
      border-color: #4cae4c;
    }
  }

  .btn-success.focus {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }

  .btn-info {
    &:hover {
      background-color: #5bc0de;
      border-color: #46b8da;
    }

    &:focus {
      background-color: #5bc0de;
      border-color: #46b8da;
    }
  }

  .btn-info.focus {
    background-color: #5bc0de;
    border-color: #46b8da;
  }

  .btn-warning {
    &:hover {
      background-color: #f0ad4e;
      border-color: #eea236;
    }

    &:focus {
      background-color: #f0ad4e;
      border-color: #eea236;
    }
  }

  .btn-warning.focus {
    background-color: #f0ad4e;
    border-color: #eea236;
  }

  .btn-danger {
    &:hover {
      background-color: #d9534f;
      border-color: #d43f3a;
    }

    &:focus {
      background-color: #d9534f;
      border-color: #d43f3a;
    }
  }

  .btn-danger.focus {
    background-color: #d9534f;
    border-color: #d43f3a;
  }

  .btn-link {
    background-color: transparent;
    box-shadow: none;

    &:hover {
      color: #777;
      text-decoration: none;
    }

    &:focus {
      color: #777;
      text-decoration: none;
    }
  }

  .navbar-default {
    .btn-link {
      &:hover {
        color: #ccc;
      }

      &:focus {
        color: #ccc;
      }
    }
  }

  .navbar-inverse {
    .btn-link {
      &:hover {
        color: #444;
      }

      &:focus {
        color: #444;
      }
    }
  }
}

.form-group {
  margin-bottom: 15px;
}

.radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;

  label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
  }

  input[type="radio"] {
    position: absolute;
    margin-top: 4px \9;
    margin-left: -20px;
  }

  + {
    .radio {
      margin-top: -5px;
    }
  }
}

.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;

  label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
  }

  input[type="checkbox"] {
    position: absolute;
  }

  + {
    .checkbox {
      margin-top: -5px;
    }
  }
}

.radio-inline {
  input[type="radio"] {
    position: absolute;
    margin-top: 4px \9;
    margin-left: -20px;
  }

  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;

  + {
    .radio-inline {
      margin-top: 0;
      margin-left: 10px;
    }
  }
}

.checkbox-inline {
  input[type="checkbox"] {
    position: absolute;
    margin-top: 4px \9;
    margin-left: -20px;
  }

  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;

  + {
    .checkbox-inline {
      margin-top: 0;
      margin-left: 10px;
    }
  }
}

input[type="radio"][disabled] {
  cursor: not-allowed;
}

input[type="checkbox"][disabled] {
  cursor: not-allowed;
}

input[type="radio"].disabled {
  cursor: not-allowed;
}

input[type="checkbox"].disabled {
  cursor: not-allowed;
}

.radio-inline.disabled {
  cursor: not-allowed;
}

.checkbox-inline.disabled {
  cursor: not-allowed;
}

.radio.disabled {
  label {
    cursor: not-allowed;
  }
}

.checkbox.disabled {
  label {
    cursor: not-allowed;
  }
}

.form-control-static {
  min-height: 34px;
  padding: var(--input-v-padding) var(--input-h-padding);
  margin-bottom: 0;
}

.form-control-static.input-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-static.input-sm {
  padding-right: 0;
  padding-left: 0;
}

.form-control-static-action {
  display: flex;
  justify-content: space-between;

  span {
    cursor: pointer;
  }
}

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 3px;

  + {
    .form-control-feedback {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }
}

select.input-sm {
  height: 30px;
  line-height: 30px;
}

textarea.input-sm {
  height: auto;
}

select[multiple].input-sm {
  height: auto;
}

.form-group-sm {
  .form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 1.2rem;
    line-height: 1.5;
    border-radius: 3px;

    + {
      .form-control-feedback {
        width: 30px;
        height: 30px;
        line-height: 30px;
      }
    }
  }

  select.form-control {
    height: 30px;
    line-height: 30px;
  }

  textarea.form-control {
    height: auto;
  }

  select[multiple].form-control {
    height: auto;
  }

  .form-control-static {
    height: 30px;
    min-height: 32px;
    padding: 6px 10px;
    font-size: 1.2rem;
    line-height: 1.5;
  }
}

.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 1.8rem;
  line-height: 1.3333333;
  border-radius: 6px;

  + {
    .form-control-feedback {
      width: 46px;
      height: 46px;
      line-height: 46px;
    }
  }
}

select.input-lg {
  height: 46px;
  line-height: 46px;
}

textarea.input-lg {
  height: auto;
}

select[multiple].input-lg {
  height: auto;
}

.form-group-lg {
  .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 1.8rem;
    line-height: 1.3333333;
    border-radius: 6px;

    + {
      .form-control-feedback {
        width: 46px;
        height: 46px;
        line-height: 46px;
      }
    }
  }

  select.form-control {
    height: 46px;
    line-height: 46px;
  }

  textarea.form-control {
    height: auto;
  }

  select[multiple].form-control {
    height: auto;
  }

  .form-control-static {
    height: 46px;
    min-height: 38px;
    padding: 11px 16px;
    font-size: 1.8rem;
    line-height: 1.3333333;
  }
}

.has-feedback {
  position: relative;

  .form-control {
    padding-right: 42.5px;
  }

  label {
    ~ {
      .form-control-feedback {
        top: 25px;
      }
    }
  }

  label.sr-only {
    ~ {
      .form-control-feedback {
        top: 0;
      }
    }
  }
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.input-group-lg {
  + {
    .form-control-feedback {
      width: 46px;
      height: 46px;
      line-height: 46px;
    }
  }

  >.form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 1.8rem;
    line-height: 1.3333333;
    border-radius: 6px;
  }

  >.input-group-addon {
    height: 46px;
    padding: 10px 16px;
    font-size: 1.8rem;
    line-height: 1.3333333;
    border-radius: 6px;
  }

  >.input-group-btn {
    >.btn {
      height: 46px;
      padding: 10px 16px;
      font-size: 1.8rem;
      line-height: 1.3333333;
      border-radius: 6px;
    }
  }
}

.input-group-sm {
  + {
    .form-control-feedback {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }

  >.form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 1.2rem;
    line-height: 1.5;
    border-radius: 3px;
  }

  >.input-group-addon {
    height: 30px;
    padding: 5px 10px;
    font-size: 1.2rem;
    line-height: 1.5;
    border-radius: 3px;
  }

  >.input-group-btn {
    >.btn {
      height: 30px;
      padding: 5px 10px;
      font-size: 1.2rem;
      line-height: 1.5;
      border-radius: 3px;
    }
  }
}

.has-success {
  .help-block {
    color: #3c763d;
  }

  .control-label {
    color: #3c763d;
  }

  .radio {
    color: #3c763d;
  }

  .checkbox {
    color: #3c763d;
  }

  .radio-inline {
    color: #3c763d;
  }

  .checkbox-inline {
    color: #3c763d;
  }

  .form-control {
    border-color: #3c763d;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

    &:focus {
      border-color: #2b542c;
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 6px #67b168;
    }
  }

  .input-group-addon {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #3c763d;
  }

  .form-control-feedback {
    color: #3c763d;
  }
}

.has-success.radio {
  label {
    color: #3c763d;
  }
}

.has-success.checkbox {
  label {
    color: #3c763d;
  }
}

.has-success.radio-inline {
  label {
    color: #3c763d;
  }
}

.has-success.checkbox-inline {
  label {
    color: #3c763d;
  }
}

.has-warning {
  .help-block {
    color: #8a6d3b;
  }

  .control-label {
    color: #8a6d3b;
  }

  .radio {
    color: #8a6d3b;
  }

  .checkbox {
    color: #8a6d3b;
  }

  .radio-inline {
    color: #8a6d3b;
  }

  .checkbox-inline {
    color: #8a6d3b;
  }

  .form-control {
    border-color: #8a6d3b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

    &:focus {
      border-color: #66512c;
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 6px #c0a16b;
    }
  }

  .input-group-addon {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #8a6d3b;
  }

  .form-control-feedback {
    color: #8a6d3b;
  }
}

.has-warning.radio {
  label {
    color: #8a6d3b;
  }
}

.has-warning.checkbox {
  label {
    color: #8a6d3b;
  }
}

.has-warning.radio-inline {
  label {
    color: #8a6d3b;
  }
}

.has-warning.checkbox-inline {
  label {
    color: #8a6d3b;
  }
}

.has-error {
  .help-block {
    color: #a94442;
  }

  .control-label {
    color: #a94442;
  }

  .radio {
    color: #a94442;
  }

  .checkbox {
    color: #a94442;
  }

  .radio-inline {
    color: #a94442;
  }

  .checkbox-inline {
    color: #a94442;
  }

  .form-control {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

    &:focus {
      border-color: #843534;
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 6px #ce8483;
    }
  }

  .input-group-addon {
    color: #a94442;
    background-color: #f2dede;
    border-color: #a94442;
  }

  .form-control-feedback {
    color: #a94442;
  }
}

.has-error.radio {
  label {
    color: #a94442;
  }
}

.has-error.checkbox {
  label {
    color: #a94442;
  }
}

.has-error.radio-inline {
  label {
    color: #a94442;
  }
}

.has-error.checkbox-inline {
  label {
    color: #a94442;
  }
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

.form-horizontal {
  .radio {
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0;
    min-height: 27px;
  }

  .checkbox {
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0;
    min-height: 27px;
  }

  .radio-inline {
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .checkbox-inline {
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .form-group {
    margin-right: -15px;
    margin-left: -15px;

    &:before {
      display: table;
      content: " ";
    }

    &:after {
      display: table;
      content: " ";
      clear: both;
    }
  }

  .has-feedback {
    .form-control-feedback {
      right: 15px;
    }
  }
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: var(--font-size-base);
  font-weight: normal;
  line-height: var(--line-height);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;

  &:focus {
    outline: var(--accessibility-outline);
    outline-offset: var(--accessibility-outline-offset);
  }

  &:active {
    &:focus {
      outline: var(--accessibility-outline);
      outline-offset: var(--accessibility-outline-offset);
    }
  }

  &:active.focus {
    outline: var(--accessibility-outline);
    outline-offset: var(--accessibility-outline-offset);
  }

  .caret {
    margin-left: 0;
  }

  .label {
    position: relative;
    top: -1px;
  }

  .badge {
    position: relative;
    top: -1px;
  }
}

.btn.active {
  &:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
  color: #333;
  text-decoration: none;
}

.btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn.disabled {
  cursor: not-allowed;
  box-shadow: none;
}

.btn[disabled] {
  cursor: not-allowed;
  box-shadow: none;
}

a.btn.disabled {
  pointer-events: none;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;

  &:focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c;
  }

  &:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }

  &:active {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
    background-image: none;

    &:hover {
      color: #333;
      background-color: #d4d4d4;
      border-color: #8c8c8c;
    }

    &:focus {
      color: #333;
      background-color: #d4d4d4;
      border-color: #8c8c8c;
    }
  }

  &:active.focus {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
  }

  .badge {
    color: #fff;
    background-color: #333;
  }
}

.btn-default.focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}

.btn-default.active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
  background-image: none;

  &:hover {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
  }

  &:focus {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
  }
}

.open {
  >.dropdown-toggle.btn-default {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
    background-image: none;

    &:hover {
      color: var(--color-white);
      background-color: var(--btn-states-color);
      border-color: var(--btn-states-color);
    }

    &:focus {
      color: var(--color-white);
      background-color: var(--btn-states-color);
      border-color: var(--btn-states-color);
    }
  }

  >.dropdown-toggle.btn-default.focus {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
  }

  >.dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #286090;
    border-color: #204d74;
    background-image: none;

    &:hover {
      color: #fff;
      background-color: var(--btn-states-color);
      border-color: var(--btn-states-color);
    }

    &:focus {
      color: #fff;
      background-color: var(--btn-states-color);
      border-color: var(--btn-states-color);
    }
  }

  >.dropdown-toggle.btn-primary.focus {
    color: #fff;
    background-color: #204d74;
    border-color: #122b40;
  }

  >.dropdown-toggle.btn-success {
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
    background-image: none;

    &:hover {
      color: #fff;
      background-color: var(--btn-states-color);
      border-color: var(--btn-states-color);
    }

    &:focus {
      color: #fff;
      background-color: var(--btn-states-color);
      border-color: var(--btn-states-color);
    }
  }

  >.dropdown-toggle.btn-success.focus {
    color: #fff;
    background-color: #398439;
    border-color: #255625;
  }

  >.dropdown-toggle.btn-info {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc;
    background-image: none;

    &:hover {
      color: #fff;
      background-color: var(--btn-states-color);
      border-color: var(--btn-states-color);
    }

    &:focus {
      color: #fff;
      background-color: var(--btn-states-color);
      border-color: var(--btn-states-color);
    }
  }

  >.dropdown-toggle.btn-info.focus {
    color: #fff;
    background-color: #269abc;
    border-color: #1b6d85;
  }

  >.dropdown-toggle.btn-warning {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512;
    background-image: none;

    &:hover {
      color: #fff;
      background-color: var(--btn-states-color);
      border-color: var(--btn-states-color);
    }

    &:focus {
      color: #fff;
      background-color: var(--btn-states-color);
      border-color: var(--btn-states-color);
    }
  }

  >.dropdown-toggle.btn-warning.focus {
    color: #fff;
    background-color: #d58512;
    border-color: #985f0d;
  }

  >.dropdown-toggle.btn-danger {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925;
    background-image: none;

    &:hover {
      color: #fff;
      background-color: var(--btn-states-color);
      border-color: var(--btn-states-color);
    }

    &:focus {
      color: #fff;
      background-color: var(--btn-states-color);
      border-color: var(--btn-states-color);
    }
  }

  >.dropdown-toggle.btn-danger.focus {
    color: #fff;
    background-color: #ac2925;
    border-color: #761c19;
  }

  >.dropdown-menu {
    display: block;
    padding: 0;
  }

  >a {
    outline: 0;
  }
}

.btn-default.active.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-default.disabled {
  &:hover {
    background-color: #fff;
    border-color: #ccc;
  }

  &:focus {
    background-color: #fff;
    border-color: #ccc;
  }
}

.btn-default[disabled] {
  &:hover {
    background-color: #fff;
    border-color: #ccc;
  }

  &:focus {
    background-color: #fff;
    border-color: #ccc;
  }
}

.btn-default.disabled.focus {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default[disabled].focus {
  background-color: #fff;
  border-color: #ccc;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;

  &:focus {
    color: #fff;
    background-color: #286090;
    border-color: #122b40;
  }

  &:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74;
  }

  &:active {
    color: #fff;
    background-color: #286090;
    border-color: #204d74;
    background-image: none;

    &:hover {
      color: #fff;
      background-color: #204d74;
      border-color: #122b40;
    }

    &:focus {
      color: #fff;
      background-color: #204d74;
      border-color: #122b40;
    }
  }

  &:active.focus {
    color: #fff;
    background-color: #204d74;
    border-color: #122b40;
  }

  .badge {
    color: #337ab7;
    background-color: #fff;
  }
}

.btn-primary.focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}

.btn-primary.active {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
  background-image: none;

  &:hover {
    color: #fff;
    background-color: #204d74;
    border-color: #122b40;
  }

  &:focus {
    color: #fff;
    background-color: #204d74;
    border-color: #122b40;
  }
}

.btn-primary.active.focus {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}

.btn-primary.disabled {
  &:hover {
    background-color: #337ab7;
    border-color: #2e6da4;
  }

  &:focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}

.btn-primary[disabled] {
  &:hover {
    background-color: #337ab7;
    border-color: #2e6da4;
  }

  &:focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}

.btn-primary.disabled.focus {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-primary[disabled].focus {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;

  &:focus {
    color: #fff;
    background-color: #449d44;
    border-color: #255625;
  }

  &:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
  }

  &:active {
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
    background-image: none;

    &:hover {
      color: #fff;
      background-color: #398439;
      border-color: #255625;
    }

    &:focus {
      color: #fff;
      background-color: #398439;
      border-color: #255625;
    }
  }

  &:active.focus {
    color: #fff;
    background-color: #398439;
    border-color: #255625;
  }

  .badge {
    color: #5cb85c;
    background-color: #fff;
  }
}

.btn-success.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625;
}

.btn-success.active {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
  background-image: none;

  &:hover {
    color: #fff;
    background-color: #398439;
    border-color: #255625;
  }

  &:focus {
    color: #fff;
    background-color: #398439;
    border-color: #255625;
  }
}

.btn-success.active.focus {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.btn-success.disabled {
  &:hover {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }

  &:focus {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

.btn-success[disabled] {
  &:hover {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }

  &:focus {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

.btn-success.disabled.focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success[disabled].focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;

  &:focus {
    color: #fff;
    background-color: #31b0d5;
    border-color: #1b6d85;
  }

  &:hover {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc;
  }

  &:active {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc;
    background-image: none;

    &:hover {
      color: #fff;
      background-color: #269abc;
      border-color: #1b6d85;
    }

    &:focus {
      color: #fff;
      background-color: #269abc;
      border-color: #1b6d85;
    }
  }

  &:active.focus {
    color: #fff;
    background-color: #269abc;
    border-color: #1b6d85;
  }

  .badge {
    color: #5bc0de;
    background-color: #fff;
  }
}

.btn-info.focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85;
}

.btn-info.active {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
  background-image: none;

  &:hover {
    color: #fff;
    background-color: #269abc;
    border-color: #1b6d85;
  }

  &:focus {
    color: #fff;
    background-color: #269abc;
    border-color: #1b6d85;
  }
}

.btn-info.active.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}

.btn-info.disabled {
  &:hover {
    background-color: #5bc0de;
    border-color: #46b8da;
  }

  &:focus {
    background-color: #5bc0de;
    border-color: #46b8da;
  }
}

.btn-info[disabled] {
  &:hover {
    background-color: #5bc0de;
    border-color: #46b8da;
  }

  &:focus {
    background-color: #5bc0de;
    border-color: #46b8da;
  }
}

.btn-info.disabled.focus {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info[disabled].focus {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;

  &:focus {
    color: #fff;
    background-color: #ec971f;
    border-color: #985f0d;
  }

  &:hover {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512;
  }

  &:active {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512;
    background-image: none;

    &:hover {
      color: #fff;
      background-color: #d58512;
      border-color: #985f0d;
    }

    &:focus {
      color: #fff;
      background-color: #d58512;
      border-color: #985f0d;
    }
  }

  &:active.focus {
    color: #fff;
    background-color: #d58512;
    border-color: #985f0d;
  }

  .badge {
    color: #f0ad4e;
    background-color: #fff;
  }
}

.btn-warning.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d;
}

.btn-warning.active {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
  background-image: none;

  &:hover {
    color: #fff;
    background-color: #d58512;
    border-color: #985f0d;
  }

  &:focus {
    color: #fff;
    background-color: #d58512;
    border-color: #985f0d;
  }
}

.btn-warning.active.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}

.btn-warning.disabled {
  &:hover {
    background-color: #f0ad4e;
    border-color: #eea236;
  }

  &:focus {
    background-color: #f0ad4e;
    border-color: #eea236;
  }
}

.btn-warning[disabled] {
  &:hover {
    background-color: #f0ad4e;
    border-color: #eea236;
  }

  &:focus {
    background-color: #f0ad4e;
    border-color: #eea236;
  }
}

.btn-warning.disabled.focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning[disabled].focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;

  &:focus {
    color: #fff;
    background-color: #c9302c;
    border-color: #761c19;
  }

  &:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925;
  }

  &:active {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925;
    background-image: none;

    &:hover {
      color: #fff;
      background-color: #ac2925;
      border-color: #761c19;
    }

    &:focus {
      color: #fff;
      background-color: #ac2925;
      border-color: #761c19;
    }
  }

  &:active.focus {
    color: #fff;
    background-color: #ac2925;
    border-color: #761c19;
  }

  .badge {
    color: #d9534f;
    background-color: #fff;
  }
}

.btn-danger.focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19;
}

.btn-danger.active {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
  background-image: none;

  &:hover {
    color: #fff;
    background-color: #ac2925;
    border-color: #761c19;
  }

  &:focus {
    color: #fff;
    background-color: #ac2925;
    border-color: #761c19;
  }
}

.btn-danger.active.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}

.btn-danger.disabled {
  &:hover {
    background-color: #d9534f;
    border-color: #d43f3a;
  }

  &:focus {
    background-color: #d9534f;
    border-color: #d43f3a;
  }
}

.btn-danger[disabled] {
  &:hover {
    background-color: #d9534f;
    border-color: #d43f3a;
  }

  &:focus {
    background-color: #d9534f;
    border-color: #d43f3a;
  }
}

.btn-danger.disabled.focus {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger[disabled].focus {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-link {
  font-weight: normal;
  color: #337ab7;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  border-color: transparent;

  &:active {
    background-color: transparent;
    box-shadow: none;
    border-color: transparent;
  }

  &:hover {
    border-color: transparent;
    color: #23527c;
    text-decoration: underline;
    background-color: transparent;
  }

  &:focus {
    border-color: transparent;
    color: #23527c;
    text-decoration: underline;
    background-color: transparent;
  }
}

.btn-link.active {
  background-color: transparent;
  box-shadow: none;
}

.btn-link[disabled] {
  background-color: transparent;
  box-shadow: none;

  &:hover {
    color: #777;
    text-decoration: none;
  }

  &:focus {
    color: #777;
    text-decoration: none;
  }
}

.btn-lg {
  padding: var(--btn-lg-padding);
  font-size: 1.8rem;
  line-height: 1.3333333;
  border-radius: 6px;

  .caret {
    border-width: 5px 5px 0;
    border-bottom-width: 0;
  }
}

.btn-group-lg {
  >.btn {
    padding: var(--btn-lg-padding);
    font-size: 1.8rem;
    line-height: 1.3333333;
    border-radius: 6px;
  }
}

.btn-sm {
  padding: var(--btn-sm-padding);
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-group-sm {
  >.btn {
    padding: var(--btn-sm-padding);
    font-size: 1.2rem;
    line-height: 1.5;
    border-radius: var(--btn-border-radius);
  }
}

.btn-xs {
  padding: var(--btn-xs-padding);
  font-size: 1.2rem;
  line-height: 1.3333333;
  border-radius: 3px;

  .badge {
    top: 0;
    padding: 1px 5px;
  }
}

.btn-group-xs {
  >.btn {
    padding: var(--btn-xs-padding);
    font-size: 1.2rem;
    line-height: 1.3333333;
    border-radius: 3px;

    .badge {
      top: 0;
      padding: 1px 5px;
    }
  }
}

.btn-block {
  display: block;
  width: 100%;

  + {
    .btn-block {
      margin-top: 5px;
    }
  }
}

input[type="submit"].btn-block {
  width: 100%;
}

input[type="reset"].btn-block {
  width: 100%;
}

input[type="button"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: 0.35s;
  transition-property: height, visibility;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup {
  position: relative;

  .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
  }

  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
  }

  .btn-lg {
    .caret {
      border-width: 0 5px 5px;
    }
  }
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 100%;
  padding: 0;
  margin: 2px 0 0;
  font-size: var(--font-size-base);
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: var(--card-border);
  border-radius: var(--btn-border-radius);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

  .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
  }

  >li {
    >a {
      align-items: center;
      border-radius: var(--btn-border-radius);
      clear: both;
      color: var(--text-color);
      display: flex;
      height: var(--app-controls-dropdown-item-height);
      padding: var(--input-v-padding) var(--input-h-padding);
      white-space: nowrap;

      &:hover {
        color: #262626;
        text-decoration: none;
        background-color: #f5f5f5;
      }

      &:focus {
        color: #262626;
        text-decoration: none;
        background-color: #f5f5f5;
      }
    }
  }

  >.active {
    >a {
      color: #fff;
      text-decoration: none;
      background-color: #337ab7;
      outline: 0;

      &:hover {
        color: #fff;
        text-decoration: none;
        background-color: #337ab7;
        outline: 0;
      }

      &:focus {
        color: #fff;
        text-decoration: none;
        background-color: #337ab7;
        outline: 0;
      }
    }
  }

  >.disabled {
    >a {
      color: #777;

      &:hover {
        color: #777;
        text-decoration: none;
        cursor: not-allowed;
        background-color: transparent;
        background-image: none;
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
      }

      &:focus {
        color: #777;
        text-decoration: none;
        cursor: not-allowed;
        background-color: transparent;
        background-image: none;
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
      }
    }
  }
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 1.2rem;
  line-height: var(--line-height);
  color: #777;
  white-space: nowrap;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.pull-right {
  >.dropdown-menu {
    right: 0;
    left: auto;
  }

  float: right !important;
}

.navbar-fixed-bottom {
  .dropdown {
    .caret {
      content: "";
      border-top: 0;
      border-bottom: 4px dashed;
      border-bottom: 4px solid \9;
    }

    .dropdown-menu {
      top: auto;
      bottom: 100%;
      margin-bottom: 2px;
    }
  }

  .navbar-collapse {
    max-height: 340px;
  }

  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0;

  .navbar-nav {
    >li {
      >.dropdown-menu {
        margin-bottom: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  >.btn {
    position: relative;
    float: left;

    &:hover {
      z-index: 2;
    }

    &:focus {
      z-index: 2;
    }

    &:active {
      z-index: 2;
    }

    &:not(:first-child) {
      &:not(:last-child) {
        &:not(.dropdown-toggle) {
          border-radius: 0;
        }
      }
    }

    &:first-child {
      margin-left: 0;

      &:not(:last-child) {
        &:not(.dropdown-toggle) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    &:last-child {
      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    + {
      .dropdown-toggle {
        padding-right: 8px;
        padding-left: 8px;
        text-overflow: hidden;
      }
    }
  }

  >.btn.active {
    z-index: 2;
  }

  .btn {
    + {
      .btn {
        margin-left: -1px;
      }

      .btn-group {
        margin-left: -1px;
      }
    }
  }

  .btn-group {
    + {
      .btn {
        margin-left: -1px;
      }

      .btn-group {
        margin-left: -1px;
      }
    }
  }

  >.dropdown-toggle {
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  >.btn-group {
    float: left;

    &:not(:first-child) {
      &:not(:last-child) {
        >.btn {
          border-radius: 0;
        }
      }
    }

    &:first-child {
      &:not(:last-child) {
        >.btn {
          &:last-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        >.dropdown-toggle {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    &:last-child {
      &:not(:first-child) {
        >.btn {
          &:first-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }

  .dropdown-toggle {
    &:active {
      outline: 0;
    }
  }

  >.btn-lg {
    + {
      .dropdown-toggle {
        padding-right: 12px;
        padding-left: 12px;
      }
    }
  }
}

.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  >.btn {
    position: relative;
    float: left;
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;

    &:hover {
      z-index: 2;
    }

    &:focus {
      z-index: 2;
    }

    &:active {
      z-index: 2;
    }

    + {
      .btn {
        margin-top: -1px;
        margin-left: 0;
      }

      .btn-group {
        margin-top: -1px;
        margin-left: 0;
      }
    }

    &:not(:first-child) {
      &:not(:last-child) {
        border-radius: 0;
      }
    }

    &:first-child {
      &:not(:last-child) {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &:last-child {
      &:not(:first-child) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }

  >.btn.active {
    z-index: 2;
  }

  >.btn-group {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;

    >.btn {
      display: block;
      float: none;
      width: 100%;
      max-width: 100%;
      float: none;
    }

    + {
      .btn {
        margin-top: -1px;
        margin-left: 0;
      }

      .btn-group {
        margin-top: -1px;
        margin-left: 0;
      }
    }

    &:not(:first-child) {
      &:not(:last-child) {
        >.btn {
          border-radius: 0;
        }
      }
    }

    &:first-child {
      &:not(:last-child) {
        >.btn {
          &:last-child {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        >.dropdown-toggle {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    &:last-child {
      &:not(:first-child) {
        >.btn {
          &:first-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }

    &:before {
      display: table;
      content: " ";
    }

    &:after {
      display: table;
      content: " ";
      clear: both;
    }
  }
}

.btn-toolbar {
  margin-left: -5px;

  .btn {
    float: left;
  }

  .btn-group {
    float: left;
  }

  .input-group {
    float: left;
  }

  >.btn {
    margin-left: 5px;
  }

  >.btn-group {
    margin-left: 5px;
  }

  >.input-group {
    margin-left: 5px;
  }

  &:before {
    display: table;
    content: " ";
  }

  &:after {
    display: table;
    content: " ";
    clear: both;
  }
}

.btn-group.open {
  .dropdown-toggle {
    outline: 0;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  .dropdown-toggle.btn-link {
    box-shadow: none;
  }
}

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;

  >.btn {
    display: table-cell;
    float: none;
    width: 1%;
  }

  >.btn-group {
    display: table-cell;
    float: none;
    width: 1%;

    .btn {
      width: 100%;
    }

    .dropdown-menu {
      left: auto;
    }
  }
}

[data-toggle="buttons"] {
  >.btn {
    input[type="radio"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }

    input[type="checkbox"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }

  >.btn-group {
    >.btn {
      input[type="radio"] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
      }

      input[type="checkbox"] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
      }
    }
  }
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;

  .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    display: table-cell;

    &:focus {
      z-index: 3;
    }

    &:not(:first-child) {
      &:not(:last-child) {
        border-radius: 0;
      }
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.input-group[class*="col-"] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}

select.input-group-lg {
  >.form-control {
    height: 46px;
    line-height: 46px;
  }

  >.input-group-addon {
    height: 46px;
    line-height: 46px;
  }

  >.input-group-btn {
    >.btn {
      height: 46px;
      line-height: 46px;
    }
  }
}

textarea.input-group-lg {
  >.form-control {
    height: auto;
  }

  >.input-group-addon {
    height: auto;
  }

  >.input-group-btn {
    >.btn {
      height: auto;
    }
  }
}

select[multiple].input-group-lg {
  >.form-control {
    height: auto;
  }

  >.input-group-addon {
    height: auto;
  }

  >.input-group-btn {
    >.btn {
      height: auto;
    }
  }
}

select.input-group-sm {
  >.form-control {
    height: 30px;
    line-height: 30px;
  }

  >.input-group-addon {
    height: 30px;
    line-height: 30px;
  }

  >.input-group-btn {
    >.btn {
      height: 30px;
      line-height: 30px;
    }
  }
}

textarea.input-group-sm {
  >.form-control {
    height: auto;
  }

  >.input-group-addon {
    height: auto;
  }

  >.input-group-btn {
    >.btn {
      height: auto;
    }
  }
}

select[multiple].input-group-sm {
  >.form-control {
    height: auto;
  }

  >.input-group-addon {
    height: auto;
  }

  >.input-group-btn {
    >.btn {
      height: auto;
    }
  }
}

.input-group-addon {
  display: table-cell;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0 var(--input-addon-h-padding);
  font-size: var(--font-size-base);
  font-weight: normal;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:not(:first-child) {
    &:not(:last-child) {
      border-radius: 0;
    }
  }

  input[type="radio"] {
    margin-top: 0;
  }

  input[type="checkbox"] {
    margin-top: 0;
  }

  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
}

.input-group-btn {
  display: table-cell;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  font-size: 0;
  white-space: nowrap;

  &:not(:first-child) {
    &:not(:last-child) {
      border-radius: 0;
    }
  }

  &:first-child {
    >.btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: -1px;

      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    >.btn-group {
      >.btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:not(:first-child) {
        >.btn {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      margin-right: -1px;
    }

    >.dropdown-toggle {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &:last-child {
    >.btn {
      &:not(:last-child) {
        &:not(.dropdown-toggle) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      z-index: 2;
      margin-left: -1px;
    }

    >.btn-group {
      &:not(:last-child) {
        >.btn {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      >.btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      z-index: 2;
      margin-left: -1px;
    }

    >.dropdown-toggle {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  >.btn {
    position: relative;

    + {
      .btn {
        margin-left: -1px;
      }
    }

    &:hover {
      z-index: 2;
    }

    &:focus {
      z-index: 2;
    }

    &:active {
      z-index: 2;
    }
  }
}

.input-group-addon.input-sm {
  padding: 5px 10px;
  font-size: 1.2rem;
  border-radius: 3px;
}

.input-group-addon.input-lg {
  padding: 10px 16px;
  font-size: 1.8rem;
  border-radius: 6px;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  >li {
    position: relative;
    display: block;

    >a {
      position: relative;
      display: block;
      padding: calc(var(--block-spacing) / 2) var(--block-spacing);

      &:hover {
        text-decoration: none;
        background-color: #eee;
      }

      &:focus {
        text-decoration: none;
        background-color: #eee;
      }

      >img {
        max-width: none;
      }
    }
  }

  >li.disabled {
    >a {
      color: #777;

      &:hover {
        color: #777;
        text-decoration: none;
        cursor: not-allowed;
        background-color: transparent;
      }

      &:focus {
        color: #777;
        text-decoration: none;
        cursor: not-allowed;
        background-color: transparent;
      }
    }
  }

  .open {
    >a {
      background-color: #eee;
      border-color: #337ab7;

      &:hover {
        background-color: #eee;
        border-color: #337ab7;
      }

      &:focus {
        background-color: #eee;
        border-color: #337ab7;
      }
    }
  }

  .nav-divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
  }

  &:before {
    display: table;
    content: " ";
  }

  &:after {
    display: table;
    content: " ";
    clear: both;
  }
}

.nav-tabs {
  border-bottom: 1px solid var(--card-border-color);

  >li {
    float: left;
    margin-bottom: -1px;

    >a {
      margin-right: 2px;
      line-height: var(--line-height);
      border: 1px solid transparent;
      border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;

      &:hover {
        border-color: #eee #eee #ddd;
      }
    }
  }

  >li.active {
    >a {
      color: #555;
      cursor: default;
      background-color: #fff;
      border: 1px solid var(--card-border-color);
      border-bottom-color: transparent;

      &:hover {
        color: #555;
        cursor: default;
        background-color: #fff;
        border: 1px solid #ddd;
        border-bottom-color: transparent;
      }

      &:focus {
        color: #555;
        cursor: default;
        background-color: #fff;
        border: 1px solid #ddd;
        border-bottom-color: transparent;
      }
    }
  }

  .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.nav-tabs.nav-justified {
  width: 100%;
  border-bottom: 0;

  >li {
    float: none;

    >a {
      margin-bottom: 5px;
      text-align: center;
      margin-right: 0;
      border-radius: 4px;
    }
  }

  >.dropdown {
    .dropdown-menu {
      top: auto;
      left: auto;
    }
  }

  >.active {
    >a {
      border: 1px solid #ddd;

      &:hover {
        border: 1px solid #ddd;
      }

      &:focus {
        border: 1px solid #ddd;
      }
    }
  }
}

.nav-pills {
  >li {
    float: left;

    >a {
      border-radius: 4px;

      >.badge {
        margin-left: 3px;
      }
    }

    + {
      li {
        margin-left: 2px;
      }
    }
  }

  >li.active {
    >a {
      color: #fff;
      background-color: #337ab7;

      &:hover {
        color: #fff;
        background-color: #337ab7;
      }

      &:focus {
        color: #fff;
        background-color: #337ab7;
      }
    }
  }

  >.active {
    >a {
      >.badge {
        color: #337ab7;
        background-color: #fff;
      }
    }
  }
}

.nav-stacked {
  >li {
    float: none;

    + {
      li {
        margin-top: 2px;
        margin-left: 0;
      }
    }
  }
}

.nav-justified {
  width: 100%;

  >li {
    float: none;

    >a {
      margin-bottom: 5px;
      text-align: center;
    }
  }

  >.dropdown {
    .dropdown-menu {
      top: auto;
      left: auto;
    }
  }
}

.nav-tabs-justified {
  border-bottom: 0;

  >li {
    >a {
      margin-right: 0;
      border-radius: 4px;
    }
  }

  >.active {
    >a {
      border: 1px solid #ddd;

      &:hover {
        border: 1px solid #ddd;
      }

      &:focus {
        border: 1px solid #ddd;
      }
    }
  }
}

.tab-content {
  >.tab-pane {
    display: none;
  }

  >.active {
    display: block;
  }
}

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;

  &:before {
    display: table;
    content: " ";
  }

  &:after {
    display: table;
    content: " ";
    clear: both;
  }
}

.navbar-collapse {
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);

  &:before {
    display: table;
    content: " ";
  }

  &:after {
    display: table;
    content: " ";
    clear: both;
  }
}

.navbar-collapse.in {
  overflow-y: auto;
}

.navbar-fixed-top {
  .navbar-collapse {
    max-height: 340px;
  }

  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  top: 0;
  border-width: 0 0 1px;
}

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 1.8rem;
  line-height: 20px;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }

  >img {
    display: block;
  }
}

.navbar-toggle {
  position: relative;
  float: right;
  padding: 0;
  margin: 0;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;

  &:focus {
    outline: 0;
  }

  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;

    + {
      .icon-bar {
        margin-top: 4px;
      }
    }
  }
}

.navbar-nav {
  margin: 7.5px -15px;

  >li {
    >a {
      padding-top: 10px;
      padding-bottom: 10px;
      line-height: 20px;
    }

    >.dropdown-menu {
      margin-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.navbar-form {
  padding: 10px 15px;
  margin-top: 8px;
  margin-right: -15px;
  margin-bottom: 8px;
  margin-left: -15px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.1),
    0 1px 0 rgba(255, 255, 255, 0.1);
}

.navbar-btn {
  margin-top: 8px;
  margin-bottom: 8px;
}

.navbar-btn.btn-sm {
  margin-top: 10px;
  margin-bottom: 10px;
}

.navbar-btn.btn-xs {
  margin-top: 14px;
  margin-bottom: 14px;
}

.navbar-text {
  margin-top: 15px;
  margin-bottom: 15px;
}

.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7;

  .navbar-brand {
    color: #777;

    &:hover {
      color: #5e5e5e;
      background-color: transparent;
    }

    &:focus {
      color: #5e5e5e;
      background-color: transparent;
    }
  }

  .navbar-text {
    color: #777;
  }

  .navbar-nav {
    >li {
      >a {
        color: #777;

        &:hover {
          color: #333;
          background-color: transparent;
        }

        &:focus {
          color: #333;
          background-color: transparent;
        }
      }
    }

    >.active {
      >a {
        color: #555;
        background-color: #e7e7e7;

        &:hover {
          color: #555;
          background-color: #e7e7e7;
        }

        &:focus {
          color: #555;
          background-color: #e7e7e7;
        }
      }
    }

    >.disabled {
      >a {
        color: #ccc;
        background-color: transparent;

        &:hover {
          color: #ccc;
          background-color: transparent;
        }

        &:focus {
          color: #ccc;
          background-color: transparent;
        }
      }
    }

    >.open {
      >a {
        color: #555;
        background-color: #e7e7e7;

        &:hover {
          color: #555;
          background-color: #e7e7e7;
        }

        &:focus {
          color: #555;
          background-color: #e7e7e7;
        }
      }
    }
  }

  .navbar-toggle {
    border-color: #ddd;

    &:hover {
      background-color: #ddd;
    }

    &:focus {
      background-color: #ddd;
    }

    .icon-bar {
      background-color: #888;
    }
  }

  .navbar-collapse {
    border-color: #e7e7e7;
  }

  .navbar-form {
    border-color: #e7e7e7;
  }

  .navbar-link {
    color: #777;

    &:hover {
      color: #333;
    }
  }

  .btn-link {
    color: #777;

    &:hover {
      color: #333;
    }

    &:focus {
      color: #333;
    }
  }

  .btn-link[disabled] {
    &:hover {
      color: #ccc;
    }

    &:focus {
      color: #ccc;
    }
  }
}

.navbar-inverse {
  background-color: #222;
  border-color: #080808;

  .navbar-brand {
    color: #9d9d9d;

    &:hover {
      color: #fff;
      background-color: transparent;
    }

    &:focus {
      color: #fff;
      background-color: transparent;
    }
  }

  .navbar-text {
    color: #9d9d9d;
  }

  .navbar-nav {
    >li {
      >a {
        color: #9d9d9d;

        &:hover {
          color: #fff;
          background-color: transparent;
        }

        &:focus {
          color: #fff;
          background-color: transparent;
        }
      }
    }

    >.active {
      >a {
        color: #fff;
        background-color: #080808;

        &:hover {
          color: #fff;
          background-color: #080808;
        }

        &:focus {
          color: #fff;
          background-color: #080808;
        }
      }
    }

    >.disabled {
      >a {
        color: #444;
        background-color: transparent;

        &:hover {
          color: #444;
          background-color: transparent;
        }

        &:focus {
          color: #444;
          background-color: transparent;
        }
      }
    }

    >.open {
      >a {
        color: #fff;
        background-color: #080808;

        &:hover {
          color: #fff;
          background-color: #080808;
        }

        &:focus {
          color: #fff;
          background-color: #080808;
        }
      }
    }
  }

  .navbar-toggle {
    border-color: #333;

    &:hover {
      background-color: #333;
    }

    &:focus {
      background-color: #333;
    }

    .icon-bar {
      background-color: #fff;
    }
  }

  .navbar-collapse {
    border-color: #101010;
  }

  .navbar-form {
    border-color: #101010;
  }

  .navbar-link {
    color: #9d9d9d;

    &:hover {
      color: #fff;
    }
  }

  .btn-link {
    color: #9d9d9d;

    &:hover {
      color: #fff;
    }

    &:focus {
      color: #fff;
    }
  }

  .btn-link[disabled] {
    &:hover {
      color: #444;
    }

    &:focus {
      color: #444;
    }
  }
}

.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 4px;

  >li {
    display: inline-block;

    + {
      li {
        &:before {
          padding: 0 5px;
          color: #ccc;
          content: "/\00a0";
        }
      }
    }
  }

  >.active {
    color: #777;
  }
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: var(--block-spacing) 0 0;

  >li {
    display: inline;

    >a {
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: var(--line-height);
      color: #337ab7;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ddd;

      &:hover {
        z-index: 2;
        color: #23527c;
        background-color: #eee;
        border-color: #ddd;
      }

      &:focus {
        z-index: 2;
        color: #23527c;
        background-color: #eee;
        border-color: #ddd;
      }
    }

    >span {
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: var(--line-height);
      color: #337ab7;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ddd;

      &:hover {
        z-index: 2;
        color: #23527c;
        background-color: #eee;
        border-color: #ddd;
      }

      &:focus {
        z-index: 2;
        color: #23527c;
        background-color: #eee;
        border-color: #ddd;
      }
    }

    &:first-child {
      >a {
        margin-left: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      >span {
        margin-left: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    &:last-child {
      >a {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      >span {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  >.active {
    >a {
      z-index: 3;
      color: #fff;
      cursor: default;
      background-color: #337ab7;
      border-color: #337ab7;

      &:hover {
        z-index: 3;
        color: #fff;
        cursor: default;
        background-color: #337ab7;
        border-color: #337ab7;
      }

      &:focus {
        z-index: 3;
        color: #fff;
        cursor: default;
        background-color: #337ab7;
        border-color: #337ab7;
      }
    }

    >span {
      z-index: 3;
      color: #fff;
      cursor: default;
      background-color: #337ab7;
      border-color: #337ab7;

      &:hover {
        z-index: 3;
        color: #fff;
        cursor: default;
        background-color: #337ab7;
        border-color: #337ab7;
      }

      &:focus {
        z-index: 3;
        color: #fff;
        cursor: default;
        background-color: #337ab7;
        border-color: #337ab7;
      }
    }
  }

  >.disabled {
    >span {
      color: #777;
      cursor: not-allowed;
      background-color: #fff;
      border-color: #ddd;

      &:hover {
        color: #777;
        cursor: not-allowed;
        background-color: #fff;
        border-color: #ddd;
      }

      &:focus {
        color: #777;
        cursor: not-allowed;
        background-color: #fff;
        border-color: #ddd;
      }
    }

    >a {
      color: #777;
      cursor: not-allowed;
      background-color: #fff;
      border-color: #ddd;

      &:hover {
        color: #777;
        cursor: not-allowed;
        background-color: #fff;
        border-color: #ddd;
      }

      &:focus {
        color: #777;
        cursor: not-allowed;
        background-color: #fff;
        border-color: #ddd;
      }
    }
  }
}

.pagination-lg {
  >li {
    >a {
      padding: 10px 16px;
      font-size: 1.8rem;
      line-height: 1.3333333;
    }

    >span {
      padding: 10px 16px;
      font-size: 1.8rem;
      line-height: 1.3333333;
    }

    &:first-child {
      >a {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      >span {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }

    &:last-child {
      >a {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      >span {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}

.pagination-sm {
  >li {
    >a {
      padding: 5px 10px;
      font-size: 1.2rem;
      line-height: 1.5;
    }

    >span {
      padding: 5px 10px;
      font-size: 1.2rem;
      line-height: 1.5;
    }

    &:first-child {
      >a {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      >span {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }

    &:last-child {
      >a {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }

      >span {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
}

.pager {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;

  li {
    display: inline;

    >a {
      display: inline-block;
      padding: 5px 14px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 15px;

      &:hover {
        text-decoration: none;
        background-color: #eee;
      }

      &:focus {
        text-decoration: none;
        background-color: #eee;
      }
    }

    >span {
      display: inline-block;
      padding: 5px 14px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 15px;
    }
  }

  .next {
    >a {
      float: right;
    }

    >span {
      float: right;
    }
  }

  .previous {
    >a {
      float: left;
    }

    >span {
      float: left;
    }
  }

  .disabled {
    >a {
      color: #777;
      cursor: not-allowed;
      background-color: #fff;

      &:hover {
        color: #777;
        cursor: not-allowed;
        background-color: #fff;
      }

      &:focus {
        color: #777;
        cursor: not-allowed;
        background-color: #fff;
      }
    }

    >span {
      color: #777;
      cursor: not-allowed;
      background-color: #fff;
    }
  }

  &:before {
    display: table;
    content: " ";
  }

  &:after {
    display: table;
    content: " ";
    clear: both;
  }
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;

  &:empty {
    display: none;
  }
}

a.label {
  &:hover {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
}

.label-default {
  background-color: #777;
}

.label-default[href] {
  &:hover {
    background-color: #5e5e5e;
  }

  &:focus {
    background-color: #5e5e5e;
  }
}

.label-primary {
  background-color: #337ab7;
}

.label-primary[href] {
  &:hover {
    background-color: #286090;
  }

  &:focus {
    background-color: #286090;
  }
}

.label-success {
  background-color: #5cb85c;
}

.label-success[href] {
  &:hover {
    background-color: #449d44;
  }

  &:focus {
    background-color: #449d44;
  }
}

.label-info {
  background-color: #5bc0de;
}

.label-info[href] {
  &:hover {
    background-color: #31b0d5;
  }

  &:focus {
    background-color: #31b0d5;
  }
}

.label-warning {
  background-color: #f0ad4e;
}

.label-warning[href] {
  &:hover {
    background-color: #ec971f;
  }

  &:focus {
    background-color: #ec971f;
  }
}

.label-danger {
  background-color: #d9534f;
}

.label-danger[href] {
  &:hover {
    background-color: #c9302c;
  }

  &:focus {
    background-color: #c9302c;
  }
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;

  &:empty {
    display: none;
  }
}

a.badge {
  &:hover {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
}

.list-group-item.active {
  >.badge {
    color: #337ab7;
    background-color: #fff;
  }

  z-index: 2;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;

  &:hover {
    z-index: 2;
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;

    .list-group-item-heading {
      color: inherit;

      >small {
        color: inherit;
      }

      >.small {
        color: inherit;
      }
    }

    .list-group-item-text {
      color: #c7ddef;
    }
  }

  &:focus {
    z-index: 2;
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;

    .list-group-item-heading {
      color: inherit;

      >small {
        color: inherit;
      }

      >.small {
        color: inherit;
      }
    }

    .list-group-item-text {
      color: #c7ddef;
    }
  }

  .list-group-item-heading {
    color: inherit;

    >small {
      color: inherit;
    }

    >.small {
      color: inherit;
    }
  }

  .list-group-item-text {
    color: #c7ddef;
  }
}

.list-group-item {
  >.badge {
    float: right;

    + {
      .badge {
        margin-right: 5px;
      }
    }
  }

  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.jumbotron {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  color: inherit;
  background-color: #eee;

  h1 {
    color: inherit;
  }

  .h1 {
    color: inherit;
  }

  p {
    margin-bottom: 15px;
    font-size: 2.1rem;
    font-weight: 200;
  }

  >hr {
    border-top-color: #d5d5d5;
  }

  .container {
    max-width: 100%;
  }
}

a.thumbnail {
  &:hover {
    border-color: #337ab7;
  }

  &:focus {
    border-color: #337ab7;
  }
}

a.thumbnail.active {
  border-color: #337ab7;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;

  h4 {
    margin-top: 0;
    color: inherit;
  }

  .alert-link {
    font-weight: bold;
  }

  >p {
    margin-bottom: 0;

    + {
      p {
        margin-top: 5px;
      }
    }
  }

  >ul {
    margin-bottom: 0;
  }
}

.alert-dismissable {
  padding-right: 35px;

  .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
  }
}

.alert-dismissible {
  padding-right: 35px;

  .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
  }
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;

  hr {
    border-top-color: #c9e2b3;
  }

  .alert-link {
    color: #2b542c;
  }
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;

  hr {
    border-top-color: #a6e1ec;
  }

  .alert-link {
    color: #245269;
  }
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;

  hr {
    border-top-color: #f7e1b5;
  }

  .alert-link {
    color: #66512c;
  }
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;

  hr {
    border-top-color: #e4b9c0;
  }

  .alert-link {
    color: #843534;
  }
}

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 1.2rem;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: width 0.6s ease;
}

.progress-striped {
  .progress-bar {
    background-image: linear-gradient(45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent);
    background-size: 40px 40px;
  }

  .progress-bar-success {
    background-image: linear-gradient(45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent);
  }

  .progress-bar-info {
    background-image: linear-gradient(45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent);
  }

  .progress-bar-warning {
    background-image: linear-gradient(45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent);
  }

  .progress-bar-danger {
    background-image: linear-gradient(45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent);
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-size: 40px 40px;
}

.progress.active {
  .progress-bar {
    animation: progress-bar-stripes 2s linear infinite;
  }
}

.progress-bar.active {
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar-success {
  background-color: #5cb85c;
}

.progress-bar-info {
  background-color: #5bc0de;
}

.progress-bar-warning {
  background-color: #f0ad4e;
}

.progress-bar-danger {
  background-color: #d9534f;
}

.media {
  margin-top: 15px;
  overflow: hidden;
  zoom: 1;

  &:first-child {
    margin-top: 0;
  }

  >.pull-right {
    padding-left: 10px;
  }

  >.pull-left {
    padding-right: 10px;
  }
}

.media-body {
  overflow: hidden;
  zoom: 1;
  width: 10000px;
  display: table-cell;
  vertical-align: top;
}

.media-object {
  display: block;
}

.media-object.img-thumbnail {
  max-width: none;
}

.media-right {
  padding-left: 10px;
  display: table-cell;
  vertical-align: top;
}

.media-left {
  padding-right: 10px;
  display: table-cell;
  vertical-align: top;
}

.media-middle {
  vertical-align: middle;
}

.media-bottom {
  vertical-align: bottom;
}

.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.media-list {
  padding-left: 0;
  list-style: none;
}

.list-group {
  padding-left: 0;
  margin-bottom: 20px;

  + {
    .panel-footer {
      border-top-width: 0;
    }
  }
}

a.list-group-item {
  color: #555;

  .list-group-item-heading {
    color: #333;
  }

  &:hover {
    color: #555;
    text-decoration: none;
    background-color: #f5f5f5;
  }

  &:focus {
    color: #555;
    text-decoration: none;
    background-color: #f5f5f5;
  }
}

button.list-group-item {
  color: #555;
  width: 100%;
  text-align: left;

  .list-group-item-heading {
    color: #333;
  }

  &:hover {
    color: #555;
    text-decoration: none;
    background-color: #f5f5f5;
  }

  &:focus {
    color: #555;
    text-decoration: none;
    background-color: #f5f5f5;
  }
}

.list-group-item.disabled {
  color: #777;
  cursor: not-allowed;
  background-color: #eee;

  &:hover {
    color: #777;
    cursor: not-allowed;
    background-color: #eee;

    .list-group-item-heading {
      color: inherit;
    }

    .list-group-item-text {
      color: #777;
    }
  }

  &:focus {
    color: #777;
    cursor: not-allowed;
    background-color: #eee;

    .list-group-item-heading {
      color: inherit;
    }

    .list-group-item-text {
      color: #777;
    }
  }

  .list-group-item-heading {
    color: inherit;
  }

  .list-group-item-text {
    color: #777;
  }
}

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}

a.list-group-item-success {
  color: #3c763d;

  .list-group-item-heading {
    color: inherit;
  }

  &:hover {
    color: #3c763d;
    background-color: #d0e9c6;
  }

  &:focus {
    color: #3c763d;
    background-color: #d0e9c6;
  }
}

button.list-group-item-success {
  color: #3c763d;

  .list-group-item-heading {
    color: inherit;
  }

  &:hover {
    color: #3c763d;
    background-color: #d0e9c6;
  }

  &:focus {
    color: #3c763d;
    background-color: #d0e9c6;
  }
}

a.list-group-item-success.active {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;

  &:hover {
    color: #fff;
    background-color: #3c763d;
    border-color: #3c763d;
  }

  &:focus {
    color: #fff;
    background-color: #3c763d;
    border-color: #3c763d;
  }
}

button.list-group-item-success.active {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;

  &:hover {
    color: #fff;
    background-color: #3c763d;
    border-color: #3c763d;
  }

  &:focus {
    color: #fff;
    background-color: #3c763d;
    border-color: #3c763d;
  }
}

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}

a.list-group-item-info {
  color: #31708f;

  .list-group-item-heading {
    color: inherit;
  }

  &:hover {
    color: #31708f;
    background-color: #c4e3f3;
  }

  &:focus {
    color: #31708f;
    background-color: #c4e3f3;
  }
}

button.list-group-item-info {
  color: #31708f;

  .list-group-item-heading {
    color: inherit;
  }

  &:hover {
    color: #31708f;
    background-color: #c4e3f3;
  }

  &:focus {
    color: #31708f;
    background-color: #c4e3f3;
  }
}

a.list-group-item-info.active {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;

  &:hover {
    color: #fff;
    background-color: #31708f;
    border-color: #31708f;
  }

  &:focus {
    color: #fff;
    background-color: #31708f;
    border-color: #31708f;
  }
}

button.list-group-item-info.active {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;

  &:hover {
    color: #fff;
    background-color: #31708f;
    border-color: #31708f;
  }

  &:focus {
    color: #fff;
    background-color: #31708f;
    border-color: #31708f;
  }
}

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}

a.list-group-item-warning {
  color: #8a6d3b;

  .list-group-item-heading {
    color: inherit;
  }

  &:hover {
    color: #8a6d3b;
    background-color: #faf2cc;
  }

  &:focus {
    color: #8a6d3b;
    background-color: #faf2cc;
  }
}

button.list-group-item-warning {
  color: #8a6d3b;

  .list-group-item-heading {
    color: inherit;
  }

  &:hover {
    color: #8a6d3b;
    background-color: #faf2cc;
  }

  &:focus {
    color: #8a6d3b;
    background-color: #faf2cc;
  }
}

a.list-group-item-warning.active {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;

  &:hover {
    color: #fff;
    background-color: #8a6d3b;
    border-color: #8a6d3b;
  }

  &:focus {
    color: #fff;
    background-color: #8a6d3b;
    border-color: #8a6d3b;
  }
}

button.list-group-item-warning.active {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;

  &:hover {
    color: #fff;
    background-color: #8a6d3b;
    border-color: #8a6d3b;
  }

  &:focus {
    color: #fff;
    background-color: #8a6d3b;
    border-color: #8a6d3b;
  }
}

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede;
}

a.list-group-item-danger {
  color: #a94442;

  .list-group-item-heading {
    color: inherit;
  }

  &:hover {
    color: #a94442;
    background-color: #ebcccc;
  }

  &:focus {
    color: #a94442;
    background-color: #ebcccc;
  }
}

button.list-group-item-danger {
  color: #a94442;

  .list-group-item-heading {
    color: inherit;
  }

  &:hover {
    color: #a94442;
    background-color: #ebcccc;
  }

  &:focus {
    color: #a94442;
    background-color: #ebcccc;
  }
}

a.list-group-item-danger.active {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;

  &:hover {
    color: #fff;
    background-color: #a94442;
    border-color: #a94442;
  }

  &:focus {
    color: #fff;
    background-color: #a94442;
    border-color: #a94442;
  }
}

button.list-group-item-danger.active {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;

  &:hover {
    color: #fff;
    background-color: #a94442;
    border-color: #a94442;
  }

  &:focus {
    color: #fff;
    background-color: #a94442;
    border-color: #a94442;
  }
}

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

  >.list-group {
    margin-bottom: 0;

    .list-group-item {
      border-width: 1px 0;
      border-radius: 0;
    }

    &:first-child {
      .list-group-item {
        &:first-child {
          border-top: 0;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }
      }
    }

    &:last-child {
      .list-group-item {
        &:last-child {
          border-bottom: 0;
          border-bottom-right-radius: 3px;
          border-bottom-left-radius: 3px;
        }
      }
    }
  }

  >.panel-collapse {
    >.list-group {
      margin-bottom: 0;

      .list-group-item {
        border-width: 1px 0;
        border-radius: 0;
      }

      &:first-child {
        .list-group-item {
          &:first-child {
            border-top: 0;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
          }
        }
      }

      &:last-child {
        .list-group-item {
          &:last-child {
            border-bottom: 0;
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;
          }
        }
      }
    }

    >.table {
      margin-bottom: 0;

      caption {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  >.panel-heading {
    + {
      .panel-collapse {
        >.list-group {
          .list-group-item {
            &:first-child {
              border-top-left-radius: 0;
              border-top-right-radius: 0;
            }
          }
        }
      }
    }
  }

  >.table {
    margin-bottom: 0;

    caption {
      padding-right: 15px;
      padding-left: 15px;
    }

    &:first-child {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      >thead {
        &:first-child {
          >tr {
            &:first-child {
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;

              td {
                &:first-child {
                  border-top-left-radius: 3px;
                }

                &:last-child {
                  border-top-right-radius: 3px;
                }
              }

              th {
                &:first-child {
                  border-top-left-radius: 3px;
                }

                &:last-child {
                  border-top-right-radius: 3px;
                }
              }
            }
          }
        }
      }

      >tbody {
        &:first-child {
          >tr {
            &:first-child {
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;

              td {
                &:first-child {
                  border-top-left-radius: 3px;
                }

                &:last-child {
                  border-top-right-radius: 3px;
                }
              }

              th {
                &:first-child {
                  border-top-left-radius: 3px;
                }

                &:last-child {
                  border-top-right-radius: 3px;
                }
              }
            }
          }
        }
      }
    }

    &:last-child {
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;

      >tbody {
        &:last-child {
          >tr {
            &:last-child {
              border-bottom-right-radius: 3px;
              border-bottom-left-radius: 3px;

              td {
                &:first-child {
                  border-bottom-left-radius: 3px;
                }

                &:last-child {
                  border-bottom-right-radius: 3px;
                }
              }

              th {
                &:first-child {
                  border-bottom-left-radius: 3px;
                }

                &:last-child {
                  border-bottom-right-radius: 3px;
                }
              }
            }
          }
        }
      }

      >tfoot {
        &:last-child {
          >tr {
            &:last-child {
              border-bottom-right-radius: 3px;
              border-bottom-left-radius: 3px;

              td {
                &:first-child {
                  border-bottom-left-radius: 3px;
                }

                &:last-child {
                  border-bottom-right-radius: 3px;
                }
              }

              th {
                &:first-child {
                  border-bottom-left-radius: 3px;
                }

                &:last-child {
                  border-bottom-right-radius: 3px;
                }
              }
            }
          }
        }
      }
    }

    + {
      .panel-body {
        border-top: 1px solid #ddd;
      }
    }

    >tbody {
      &:first-child {
        >tr {
          &:first-child {
            th {
              border-top: 0;
            }

            td {
              border-top: 0;
            }
          }
        }
      }
    }
  }

  >.table-wrapper {
    >.table {
      margin-bottom: 0;

      caption {
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    &:first-child {
      >.table {
        &:first-child {
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;

          >thead {
            &:first-child {
              >tr {
                &:first-child {
                  border-top-left-radius: 3px;
                  border-top-right-radius: 3px;

                  td {
                    &:first-child {
                      border-top-left-radius: 3px;
                    }

                    &:last-child {
                      border-top-right-radius: 3px;
                    }
                  }

                  th {
                    &:first-child {
                      border-top-left-radius: 3px;
                    }

                    &:last-child {
                      border-top-right-radius: 3px;
                    }
                  }
                }
              }
            }
          }

          >tbody {
            &:first-child {
              >tr {
                &:first-child {
                  border-top-left-radius: 3px;
                  border-top-right-radius: 3px;

                  td {
                    &:first-child {
                      border-top-left-radius: 3px;
                    }

                    &:last-child {
                      border-top-right-radius: 3px;
                    }
                  }

                  th {
                    &:first-child {
                      border-top-left-radius: 3px;
                    }

                    &:last-child {
                      border-top-right-radius: 3px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &:last-child {
      >.table {
        &:last-child {
          border-bottom-right-radius: 3px;
          border-bottom-left-radius: 3px;

          >tbody {
            &:last-child {
              >tr {
                &:last-child {
                  border-bottom-right-radius: 3px;
                  border-bottom-left-radius: 3px;

                  td {
                    &:first-child {
                      border-bottom-left-radius: 3px;
                    }

                    &:last-child {
                      border-bottom-right-radius: 3px;
                    }
                  }

                  th {
                    &:first-child {
                      border-bottom-left-radius: 3px;
                    }

                    &:last-child {
                      border-bottom-right-radius: 3px;
                    }
                  }
                }
              }
            }
          }

          >tfoot {
            &:last-child {
              >tr {
                &:last-child {
                  border-bottom-right-radius: 3px;
                  border-bottom-left-radius: 3px;

                  td {
                    &:first-child {
                      border-bottom-left-radius: 3px;
                    }

                    &:last-child {
                      border-bottom-right-radius: 3px;
                    }
                  }

                  th {
                    &:first-child {
                      border-bottom-left-radius: 3px;
                    }

                    &:last-child {
                      border-bottom-right-radius: 3px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    + {
      .panel-body {
        border-top: 1px solid #ddd;
      }
    }

    >.table-bordered {
      border: 0;

      >thead {
        >tr {
          >th {
            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }

          >td {
            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }

          &:first-child {
            >td {
              border-bottom: 0;
            }

            >th {
              border-bottom: 0;
            }
          }
        }
      }

      >tbody {
        >tr {
          >th {
            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }

          >td {
            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }

          &:first-child {
            >td {
              border-bottom: 0;
            }

            >th {
              border-bottom: 0;
            }
          }

          &:last-child {
            >td {
              border-bottom: 0;
            }

            >th {
              border-bottom: 0;
            }
          }
        }
      }

      >tfoot {
        >tr {
          >th {
            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }

          >td {
            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }

          &:last-child {
            >td {
              border-bottom: 0;
            }

            >th {
              border-bottom: 0;
            }
          }
        }
      }
    }

    margin-bottom: 0;
    border: 0;
  }

  >.panel-body {
    + {
      .table {
        border-top: 1px solid #ddd;
      }

      .table-wrapper {
        border-top: 1px solid #ddd;
      }
    }
  }

  >.table-bordered {
    border: 0;

    >thead {
      >tr {
        >th {
          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }
        }

        >td {
          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }
        }

        &:first-child {
          >td {
            border-bottom: 0;
          }

          >th {
            border-bottom: 0;
          }
        }
      }
    }

    >tbody {
      >tr {
        >th {
          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }
        }

        >td {
          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }
        }

        &:first-child {
          >td {
            border-bottom: 0;
          }

          >th {
            border-bottom: 0;
          }
        }

        &:last-child {
          >td {
            border-bottom: 0;
          }

          >th {
            border-bottom: 0;
          }
        }
      }
    }

    >tfoot {
      >tr {
        >th {
          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }
        }

        >td {
          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }
        }

        &:last-child {
          >td {
            border-bottom: 0;
          }

          >th {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.panel-body {
  padding: 15px;

  &:before {
    display: table;
    content: " ";
  }

  &:after {
    display: table;
    content: " ";
    clear: both;
  }
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  >.dropdown {
    .dropdown-toggle {
      color: inherit;
    }
  }

  + {
    .list-group {
      .list-group-item {
        &:first-child {
          border-top-width: 0;
        }
      }
    }
  }
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.6rem;
  color: inherit;

  >a {
    color: inherit;
  }

  >small {
    color: inherit;

    >a {
      color: inherit;
    }
  }

  >.small {
    color: inherit;

    >a {
      color: inherit;
    }
  }
}

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel-group {
  margin-bottom: 20px;

  .panel {
    margin-bottom: 0;
    border-radius: 4px;

    + {
      .panel {
        margin-top: 5px;
      }
    }
  }

  .panel-heading {
    border-bottom: 0;

    + {
      .panel-collapse {
        >.panel-body {
          border-top: 1px solid #ddd;
        }

        >.list-group {
          border-top: 1px solid #ddd;
        }
      }
    }
  }

  .panel-footer {
    border-top: 0;

    + {
      .panel-collapse {
        .panel-body {
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }
}

.panel-default {
  border-color: #ddd;

  >.panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;

    + {
      .panel-collapse {
        >.panel-body {
          border-top-color: #ddd;
        }
      }
    }

    .badge {
      color: #f5f5f5;
      background-color: #333;
    }
  }

  >.panel-footer {
    + {
      .panel-collapse {
        >.panel-body {
          border-bottom-color: #ddd;
        }
      }
    }
  }
}

.panel-primary {
  border-color: #337ab7;

  >.panel-heading {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;

    + {
      .panel-collapse {
        >.panel-body {
          border-top-color: #337ab7;
        }
      }
    }

    .badge {
      color: #337ab7;
      background-color: #fff;
    }
  }

  >.panel-footer {
    + {
      .panel-collapse {
        >.panel-body {
          border-bottom-color: #337ab7;
        }
      }
    }
  }
}

.panel-success {
  border-color: #d6e9c6;

  >.panel-heading {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;

    + {
      .panel-collapse {
        >.panel-body {
          border-top-color: #d6e9c6;
        }
      }
    }

    .badge {
      color: #dff0d8;
      background-color: #3c763d;
    }
  }

  >.panel-footer {
    + {
      .panel-collapse {
        >.panel-body {
          border-bottom-color: #d6e9c6;
        }
      }
    }
  }
}

.panel-info {
  border-color: #bce8f1;

  >.panel-heading {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;

    + {
      .panel-collapse {
        >.panel-body {
          border-top-color: #bce8f1;
        }
      }
    }

    .badge {
      color: #d9edf7;
      background-color: #31708f;
    }
  }

  >.panel-footer {
    + {
      .panel-collapse {
        >.panel-body {
          border-bottom-color: #bce8f1;
        }
      }
    }
  }
}

.panel-warning {
  border-color: #faebcc;

  >.panel-heading {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;

    + {
      .panel-collapse {
        >.panel-body {
          border-top-color: #faebcc;
        }
      }
    }

    .badge {
      color: #fcf8e3;
      background-color: #8a6d3b;
    }
  }

  >.panel-footer {
    + {
      .panel-collapse {
        >.panel-body {
          border-bottom-color: #faebcc;
        }
      }
    }
  }
}

.panel-danger {
  border-color: #ebccd1;

  >.panel-heading {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;

    + {
      .panel-collapse {
        >.panel-body {
          border-top-color: #ebccd1;
        }
      }
    }

    .badge {
      color: #f2dede;
      background-color: #a94442;
    }
  }

  >.panel-footer {
    + {
      .panel-collapse {
        >.panel-body {
          border-bottom-color: #ebccd1;
        }
      }
    }
  }
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;

  .embed-responsive-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  embed {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  object {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);

  blockquote {
    border-color: #ddd;
    border-color: rgba(0, 0, 0, 0.15);
  }
}

.well-lg {
  padding: 24px;
  border-radius: 6px;
}

.well-sm {
  padding: 9px;
  border-radius: 3px;
}

.close {
  float: right;
  font-size: 2.1rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;

  &:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: 0.5;
  }

  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: 0.5;
  }
}

button.close {
  appearance: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.modal-open {
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade {
  .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%);
  }
}

.modal.in {
  .modal-dialog {
    transform: translate(0, 0);
  }
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;

  .close {
    margin-top: -2px;
  }

  &:before {
    display: table;
    content: " ";
  }

  &:after {
    display: table;
    content: " ";
    clear: both;
  }
}

.modal-title {
  margin: 0;
  line-height: var(--line-height);
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;

  .btn {
    margin-bottom: 0;

    +.btn {
      margin-left: calc(2 * var(--btns-margin));
    }
  }

  .btn-group {
    .btn {
      + {
        .btn {
          margin-left: -1px;
        }
      }
    }
  }

  .btn-block {
    + {
      .btn-block {
        margin-left: 0;
      }
    }
  }

  &:before {
    display: table;
    content: " ";
  }

  &:after {
    display: table;
    content: " ";
    clear: both;
  }
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: normal;
  line-height: var(--line-height);
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  opacity: 0;
  line-break: auto;
}

// .tooltip.in {
// }

.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;

  .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
  }
}

.tooltip.right {
  padding: 0 5px;
  margin-left: 3px;

  .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
  }
}

.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;

  .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
  }
}

.tooltip.left {
  padding: 0 5px;
  margin-left: -3px;

  .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
  }
}

.tooltip-inner {
  padding: 3px 8px;
  text-align: center;
  border-radius: var(--border-radius-base);
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top-left {
  .tooltip-arrow {
    right: 5px;
    bottom: 0;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
  }
}

.tooltip.top-right {
  .tooltip-arrow {
    bottom: 0;
    left: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
  }
}

.tooltip.bottom-left {
  .tooltip-arrow {
    top: 0;
    right: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
  }
}

.tooltip.bottom-right {
  .tooltip-arrow {
    top: 0;
    left: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
  }
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-size: var(--font-size-base);
  font-style: normal;
  font-weight: normal;
  line-height: var(--line-height);
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;

  >.arrow {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 11px;

    &:after {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      content: "";
      border-width: 10px;
    }
  }
}

.popover.top {
  margin-top: -10px;

  >.arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, 0.25);
    border-bottom-width: 0;

    &:after {
      bottom: 1px;
      margin-left: -10px;
      content: " ";
      border-top-color: #fff;
      border-bottom-width: 0;
    }
  }
}

.popover.right {
  margin-left: 10px;

  >.arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-right-color: #999;
    border-right-color: rgba(0, 0, 0, 0.25);
    border-left-width: 0;

    &:after {
      bottom: -10px;
      left: 1px;
      content: " ";
      border-right-color: #fff;
      border-left-width: 0;
    }
  }
}

.popover.bottom {
  margin-top: 10px;

  >.arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, 0.25);

    &:after {
      top: 1px;
      margin-left: -10px;
      content: " ";
      border-top-width: 0;
      border-bottom-color: #fff;
    }
  }
}

.popover.left {
  margin-left: -10px;

  >.arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0, 0, 0, 0.25);

    &:after {
      right: 1px;
      bottom: -10px;
      content: " ";
      border-right-width: 0;
      border-left-color: #fff;
    }
  }
}

.popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: var(--font-size-base);
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.popover-content {
  padding: 9px 14px;
}

.clearfix {
  &:before {
    display: table;
    content: " ";
  }

  &:after {
    display: table;
    content: " ";
    clear: both;
  }
}

.dl-horizontal {
  dd {
    &:before {
      display: table;
      content: " ";
    }

    &:after {
      display: table;
      content: " ";
      clear: both;
    }
  }
}

.navbar-header {
  &:before {
    display: table;
    content: " ";
  }

  &:after {
    display: table;
    content: " ";
    clear: both;
  }
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}

.visible-xs {
  display: none !important;
}

.visible-sm {
  display: none !important;
}

.visible-md {
  display: none !important;
}

.visible-lg {
  display: none !important;
}

.visible-xs-block {
  display: none !important;
}

.visible-xs-inline {
  display: none !important;
}

.visible-xs-inline-block {
  display: none !important;
}

.visible-sm-block {
  display: none !important;
}

.visible-sm-inline {
  display: none !important;
}

.visible-sm-inline-block {
  display: none !important;
}

.visible-md-block {
  display: none !important;
}

.visible-md-inline {
  display: none !important;
}

.visible-md-inline-block {
  display: none !important;
}

.visible-lg-block {
  display: none !important;
}

.visible-lg-inline {
  display: none !important;
}

.visible-lg-inline-block {
  display: none !important;
}

.visible-print {
  display: none !important;
}

.visible-print-block {
  display: none !important;
}

.visible-print-inline {
  display: none !important;
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  * {
    text-shadow: none !important;
    box-shadow: none !important;

    &:before {
      color: #000 !important;
      text-shadow: none !important;
      background: transparent !important;
      box-shadow: none !important;
    }

    &:after {
      color: #000 !important;
      text-shadow: none !important;
      background: transparent !important;
      box-shadow: none !important;
    }
  }

  a {
    text-decoration: underline;

    &:visited {
      text-decoration: underline;
    }
  }

  // we don't want to display the href of links when printing markdowns
  // a[href] {
  //   &:after {
  //     content: " (" attr(href) ")";
  //   }
  // }
  abbr[title] {
    &:after {
      content: " (" attr(title) ")";
    }
  }

  a[href^="#"] {
    &:after {
      content: "";
    }
  }

  a[href^="javascript:"] {
    &:after {
      content: "";
    }
  }

  pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr {
    page-break-inside: avoid;
  }

  img {
    page-break-inside: avoid;
    max-width: 100% !important;
  }

  p {
    orphans: 3;
    widows: 3;
  }

  h2 {
    orphans: 3;
    widows: 3;
    page-break-after: avoid;
  }

  h3 {
    orphans: 3;
    widows: 3;
    page-break-after: avoid;
  }

  .navbar {
    display: none;
  }

  .btn {
    >.caret {
      border-top-color: #000 !important;
    }
  }

  .dropup {
    >.btn {
      >.caret {
        border-top-color: #000 !important;
      }
    }
  }

  .label {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;

    td {
      background-color: #fff !important;
    }

    th {
      background-color: #fff !important;
    }
  }

  .table-bordered {
    th {
      border: 1px solid #ddd !important;
    }

    td {
      border: 1px solid #ddd !important;
    }
  }

  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table !important;
  }

  tr.visible-print {
    display: table-row !important;
  }

  th.visible-print {
    display: table-cell !important;
  }

  td.visible-print {
    display: table-cell !important;
  }

  .visible-print-block {
    display: block !important;
  }

  .visible-print-inline {
    display: inline !important;
  }

  .visible-print-inline-block {
    display: inline-block !important;
  }

  .hidden-print {
    display: none !important;
  }
}

@media (min-width: $screen-sm) {
  .lead {
    font-size: 2.1rem;
  }

  .dl-horizontal {
    dt {
      float: left;
      width: 160px;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    dd {
      margin-left: 180px;
    }
  }

  .container {
    width: 750px;

    >.navbar-header {
      margin-right: 0;
      margin-left: 0;
    }

    >.navbar-collapse {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .col-sm-1 {
    float: left;
    width: 8.33333333%;
  }

  .col-sm-2 {
    float: left;
    width: 16.66666667%;
  }

  .col-sm-3 {
    float: left;
    width: 25%;
  }

  .col-sm-4 {
    float: left;
    width: 33.33333333%;
  }

  .col-sm-5 {
    float: left;
    width: 41.66666667%;
  }

  .col-sm-6 {
    float: left;
    width: 50%;
  }

  .col-sm-7 {
    float: left;
    width: 58.33333333%;
  }

  .col-sm-8 {
    float: left;
    width: 66.66666667%;
  }

  .col-sm-9 {
    float: left;
    width: 75%;
  }

  .col-sm-10 {
    float: left;
    width: 83.33333333%;
  }

  .col-sm-11 {
    float: left;
    width: 91.66666667%;
  }

  .col-sm-12 {
    float: left;
    width: 100%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-pull-11 {
    right: 91.66666667%;
  }

  .col-sm-pull-10 {
    right: 83.33333333%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-8 {
    right: 66.66666667%;
  }

  .col-sm-pull-7 {
    right: 58.33333333%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-5 {
    right: 41.66666667%;
  }

  .col-sm-pull-4 {
    right: 33.33333333%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-2 {
    right: 16.66666667%;
  }

  .col-sm-pull-1 {
    right: 8.33333333%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-push-11 {
    left: 91.66666667%;
  }

  .col-sm-push-10 {
    left: 83.33333333%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-8 {
    left: 66.66666667%;
  }

  .col-sm-push-7 {
    left: 58.33333333%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-5 {
    left: 41.66666667%;
  }

  .col-sm-push-4 {
    left: 33.33333333%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-2 {
    left: 16.66666667%;
  }

  .col-sm-push-1 {
    left: 8.33333333%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }

  .form-inline {
    .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }

    .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }

    .form-control-static {
      display: inline-block;
    }

    .input-group {
      display: inline-table;
      vertical-align: middle;

      .input-group-addon {
        width: auto;
      }

      .input-group-btn {
        width: auto;
      }

      .form-control {
        width: auto;
      }

      >.form-control {
        width: 100%;
      }
    }

    .control-label {
      margin-bottom: 0;
      vertical-align: middle;
    }

    .radio {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;

      label {
        padding-left: 0;
      }

      input[type="radio"] {
        position: relative;
        margin-left: 0;
      }
    }

    .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;

      label {
        padding-left: 0;
      }

      input[type="checkbox"] {
        position: relative;
        margin-left: 0;
      }
    }

    .has-feedback {
      .form-control-feedback {
        top: 0;
      }
    }
  }

  .form-horizontal {
    .control-label {
      padding-top: 7px;
      margin-bottom: 0;
      text-align: right;
    }

    .form-group-lg {
      .control-label {
        padding-top: 11px;
        font-size: 1.8rem;
      }
    }

    .form-group-sm {
      .control-label {
        padding-top: 6px;
        font-size: 1.2rem;
      }
    }
  }

  .navbar-right {
    .dropdown-menu {
      right: 0;
      left: auto;
    }

    .dropdown-menu-left {
      right: auto;
      left: 0;
    }

    float: right !important;
    margin-right: -15px;

    ~ {
      .navbar-right {
        margin-right: 0;
      }
    }
  }

  .nav-tabs.nav-justified {
    >li {
      display: table-cell;
      width: 1%;

      >a {
        margin-bottom: 0;
        border-bottom: 1px solid #ddd;
        border-radius: 4px 4px 0 0;
      }
    }

    >.active {
      >a {
        border-bottom-color: #fff;

        &:hover {
          border-bottom-color: #fff;
        }

        &:focus {
          border-bottom-color: #fff;
        }
      }
    }
  }

  .nav-justified {
    >li {
      display: table-cell;
      width: 1%;

      >a {
        margin-bottom: 0;
      }
    }
  }

  .nav-tabs-justified {
    >li {
      >a {
        border-bottom: 1px solid #ddd;
        border-radius: 4px 4px 0 0;
      }
    }

    >.active {
      >a {
        border-bottom-color: #fff;

        &:hover {
          border-bottom-color: #fff;
        }

        &:focus {
          border-bottom-color: #fff;
        }
      }
    }
  }

  .navbar {
    border-radius: 4px;

    >.container {
      .navbar-brand {
        margin-left: -15px;
      }
    }
  }

  .navbar-header {
    float: left;
  }

  .navbar-collapse {
    width: auto;
    border-top: 0;
    box-shadow: none;
  }

  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }

  .navbar-collapse.in {
    overflow-y: visible;
  }

  .navbar-fixed-top {
    .navbar-collapse {
      padding-right: 0;
      padding-left: 0;
    }

    border-radius: 0;
  }

  .navbar-static-top {
    .navbar-collapse {
      padding-right: 0;
      padding-left: 0;
    }

    border-radius: 0;
  }

  .navbar-fixed-bottom {
    .navbar-collapse {
      padding-right: 0;
      padding-left: 0;
    }

    border-radius: 0;
  }

  .navbar-toggle {
    display: none;
  }

  .navbar-nav {
    float: left;
    margin: 0;

    >li {
      float: left;

      >a {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }

  .navbar-form {
    .form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }

    .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }

    .form-control-static {
      display: inline-block;
    }

    .input-group {
      display: inline-table;
      vertical-align: middle;

      .input-group-addon {
        width: auto;
      }

      .input-group-btn {
        width: auto;
      }

      .form-control {
        width: auto;
      }

      >.form-control {
        width: 100%;
      }
    }

    .control-label {
      margin-bottom: 0;
      vertical-align: middle;
    }

    .radio {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;

      label {
        padding-left: 0;
      }

      input[type="radio"] {
        position: relative;
        margin-left: 0;
      }
    }

    .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;

      label {
        padding-left: 0;
      }

      input[type="checkbox"] {
        position: relative;
        margin-left: 0;
      }
    }

    .has-feedback {
      .form-control-feedback {
        top: 0;
      }
    }

    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    border: 0;
    box-shadow: none;
  }

  .navbar-text {
    float: left;
    margin-right: 15px;
    margin-left: 15px;
  }

  .navbar-left {
    float: left !important;
  }

  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }

  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }

  .modal-sm {
    width: $screen-sm;
  }

  .modal-xs {
    width: $screen-xs;
  }
}

@media (min-width: $screen-md) {
  .container {
    width: 970px;
  }

  .col-md-1 {
    float: left;
    width: 8.33333333%;
  }

  .col-md-2 {
    float: left;
    width: 16.66666667%;
  }

  .col-md-3 {
    float: left;
    width: 25%;
  }

  .col-md-4 {
    float: left;
    width: 33.33333333%;
  }

  .col-md-5 {
    float: left;
    width: 41.66666667%;
  }

  .col-md-6 {
    float: left;
    width: 50%;
  }

  .col-md-7 {
    float: left;
    width: 58.33333333%;
  }

  .col-md-8 {
    float: left;
    width: 66.66666667%;
  }

  .col-md-9 {
    float: left;
    width: 75%;
  }

  .col-md-10 {
    float: left;
    width: 83.33333333%;
  }

  .col-md-11 {
    float: left;
    width: 91.66666667%;
  }

  .col-md-12 {
    float: left;
    width: 100%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-pull-11 {
    right: 91.66666667%;
  }

  .col-md-pull-10 {
    right: 83.33333333%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-8 {
    right: 66.66666667%;
  }

  .col-md-pull-7 {
    right: 58.33333333%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-5 {
    right: 41.66666667%;
  }

  .col-md-pull-4 {
    right: 33.33333333%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-2 {
    right: 16.66666667%;
  }

  .col-md-pull-1 {
    right: 8.33333333%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-push-11 {
    left: 91.66666667%;
  }

  .col-md-push-10 {
    left: 83.33333333%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-8 {
    left: 66.66666667%;
  }

  .col-md-push-7 {
    left: 58.33333333%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-5 {
    left: 41.66666667%;
  }

  .col-md-push-4 {
    left: 33.33333333%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-2 {
    left: 16.66666667%;
  }

  .col-md-push-1 {
    left: 8.33333333%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }

  .modal-lg {
    width: 900px;
  }
}

@media (min-width: $screen-lg) {
  .container {
    width: 1170px;
  }

  .col-lg-1 {
    float: left;
    width: 8.33333333%;
  }

  .col-lg-2 {
    float: left;
    width: 16.66666667%;
  }

  .col-lg-3 {
    float: left;
    width: 25%;
  }

  .col-lg-4 {
    float: left;
    width: 33.33333333%;
  }

  .col-lg-5 {
    float: left;
    width: 41.66666667%;
  }

  .col-lg-6 {
    float: left;
    width: 50%;
  }

  .col-lg-7 {
    float: left;
    width: 58.33333333%;
  }

  .col-lg-8 {
    float: left;
    width: 66.66666667%;
  }

  .col-lg-9 {
    float: left;
    width: 75%;
  }

  .col-lg-10 {
    float: left;
    width: 83.33333333%;
  }

  .col-lg-11 {
    float: left;
    width: 91.66666667%;
  }

  .col-lg-12 {
    float: left;
    width: 100%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-pull-11 {
    right: 91.66666667%;
  }

  .col-lg-pull-10 {
    right: 83.33333333%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-8 {
    right: 66.66666667%;
  }

  .col-lg-pull-7 {
    right: 58.33333333%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-5 {
    right: 41.66666667%;
  }

  .col-lg-pull-4 {
    right: 33.33333333%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-2 {
    right: 16.66666667%;
  }

  .col-lg-pull-1 {
    right: 8.33333333%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-push-11 {
    left: 91.66666667%;
  }

  .col-lg-push-10 {
    left: 83.33333333%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-8 {
    left: 66.66666667%;
  }

  .col-lg-push-7 {
    left: 58.33333333%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-5 {
    left: 41.66666667%;
  }

  .col-lg-push-4 {
    left: 33.33333333%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-2 {
    left: 16.66666667%;
  }

  .col-lg-push-1 {
    left: 8.33333333%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }

  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table !important;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg {
    display: table-cell !important;
  }

  td.visible-lg {
    display: table-cell !important;
  }

  .visible-lg-block {
    display: block !important;
  }

  .visible-lg-inline {
    display: inline !important;
  }

  .visible-lg-inline-block {
    display: inline-block !important;
  }

  .hidden-lg {
    display: none !important;
  }
}

@media screen and (max-width: $screen-sm) {
  .table-wrapper {
    border: 1px solid #ddd;
    margin-bottom: 15px;
    width: 100%;

    >.table {
      margin-bottom: 0;

      >thead {
        >tr {
          >th {
            white-space: nowrap;
          }

          >td {
            white-space: nowrap;
          }
        }
      }

      >tbody {
        >tr {
          >th {
            white-space: nowrap;
          }

          >td {
            white-space: nowrap;
          }
        }
      }

      >tfoot {
        >tr {
          >th {
            white-space: nowrap;
          }

          >td {
            white-space: nowrap;
          }
        }
      }
    }

    >.table-bordered {
      border: 0;

      >thead {
        >tr {
          >th {
            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }

          >td {
            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }
        }
      }

      >tbody {
        >tr {
          >th {
            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }

          >td {
            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }

          &:last-child {
            >th {
              border-bottom: 0;
            }

            >td {
              border-bottom: 0;
            }
          }
        }
      }

      >tfoot {
        >tr {
          >th {
            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }

          >td {
            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }

          &:last-child {
            >th {
              border-bottom: 0;
            }

            >td {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control {
    line-height: 34px;
  }

  input[type="time"].form-control {
    line-height: 34px;
  }

  input[type="datetime-local"].form-control {
    line-height: 34px;
  }

  input[type="month"].form-control {
    line-height: 34px;
  }

  input[type="date"].input-sm {
    line-height: 30px;
  }

  input[type="time"].input-sm {
    line-height: 30px;
  }

  input[type="datetime-local"].input-sm {
    line-height: 30px;
  }

  input[type="month"].input-sm {
    line-height: 30px;
  }

  .input-group-sm {
    input[type="date"] {
      line-height: 30px;
    }

    input[type="time"] {
      line-height: 30px;
    }

    input[type="datetime-local"] {
      line-height: 30px;
    }

    input[type="month"] {
      line-height: 30px;
    }
  }

  input[type="date"].input-lg {
    line-height: 46px;
  }

  input[type="time"].input-lg {
    line-height: 46px;
  }

  input[type="datetime-local"].input-lg {
    line-height: 46px;
  }

  input[type="month"].input-lg {
    line-height: 46px;
  }

  .input-group-lg {
    input[type="date"] {
      line-height: 46px;
    }

    input[type="time"] {
      line-height: 46px;
    }

    input[type="datetime-local"] {
      line-height: 46px;
    }

    input[type="month"] {
      line-height: 46px;
    }
  }
}

@media (max-device-width: $screen-xs) and (orientation: landscape) {
  .navbar-fixed-top {
    .navbar-collapse {
      max-height: 200px;
    }
  }

  .navbar-fixed-bottom {
    .navbar-collapse {
      max-height: 200px;
    }
  }
}

@media (max-width: $screen-sm) {
  .navbar-nav {
    .open {
      .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;

        >li {
          >a {
            padding: 5px 15px 5px 25px;
            line-height: 20px;

            &:hover {
              background-image: none;
            }

            &:focus {
              background-image: none;
            }
          }
        }

        .dropdown-header {
          padding: 5px 15px 5px 25px;
        }
      }
    }
  }

  .navbar-form {
    .form-group {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .navbar-default {
    .navbar-nav {
      .open {
        .dropdown-menu {
          >li {
            >a {
              color: #777;

              &:hover {
                color: #333;
                background-color: transparent;
              }

              &:focus {
                color: #333;
                background-color: transparent;
              }
            }
          }

          >.active {
            >a {
              color: #555;
              background-color: #e7e7e7;

              &:hover {
                color: #555;
                background-color: #e7e7e7;
              }

              &:focus {
                color: #555;
                background-color: #e7e7e7;
              }
            }
          }

          >.disabled {
            >a {
              color: #ccc;
              background-color: transparent;

              &:hover {
                color: #ccc;
                background-color: transparent;
              }

              &:focus {
                color: #ccc;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }

  .navbar-inverse {
    .navbar-nav {
      .open {
        .dropdown-menu {
          >.dropdown-header {
            border-color: #080808;
          }

          .divider {
            background-color: #080808;
          }

          >li {
            >a {
              color: #9d9d9d;

              &:hover {
                color: #fff;
                background-color: transparent;
              }

              &:focus {
                color: #fff;
                background-color: transparent;
              }
            }
          }

          >.active {
            >a {
              color: #fff;
              background-color: #080808;

              &:hover {
                color: #fff;
                background-color: #080808;
              }

              &:focus {
                color: #fff;
                background-color: #080808;
              }
            }
          }

          >.disabled {
            >a {
              color: #444;
              background-color: transparent;

              &:hover {
                color: #444;
                background-color: transparent;
              }

              &:focus {
                color: #444;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }

  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table !important;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs {
    display: table-cell !important;
  }

  td.visible-xs {
    display: table-cell !important;
  }

  .visible-xs-block {
    display: block !important;
  }

  .visible-xs-inline {
    display: inline !important;
  }

  .visible-xs-inline-block {
    display: inline-block !important;
  }

  .hidden-xs {
    display: none !important;
  }
}

@media screen and (min-width: $screen-sm) {
  .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px;

    h1 {
      font-size: 6.3rem;
    }

    .h1 {
      font-size: 6.3rem;
    }
  }

  .container {
    .jumbotron {
      padding-right: 60px;
      padding-left: 60px;
    }
  }
}

@media (min-width: $screen-sm) and (max-width: $screen-md) {
  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table !important;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm {
    display: table-cell !important;
  }

  td.visible-sm {
    display: table-cell !important;
  }

  .visible-sm-block {
    display: block !important;
  }

  .visible-sm-inline {
    display: inline !important;
  }

  .visible-sm-inline-block {
    display: inline-block !important;
  }

  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: $screen-md) and (max-width: $screen-lg) {
  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table !important;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md {
    display: table-cell !important;
  }

  td.visible-md {
    display: table-cell !important;
  }

  .visible-md-block {
    display: block !important;
  }

  .visible-md-inline {
    display: inline !important;
  }

  .visible-md-inline-block {
    display: inline-block !important;
  }

  .hidden-md {
    display: none !important;
  }
}