.table {
  > {

    tbody,
    thead {
      >tr> {

        th,
        td {
          border-color: var(--color-gray-lighter);
          padding: var(--table-inner-padding);
          position: relative;
          vertical-align: middle;
        }
      }
    }
  }

  >thead>tr>th {
    border-bottom-width: 1px;
    font-size: var(--table-header-font-size);
    font-weight: 600;
  }

  &:not([data-hover-background-change="false"]) {
    >tbody>tr {
      position: relative;

      &:hover {
        background: var(--row-background-hover-color);
      }

      &.form-row,
      &.form-row:hover {
        background: white;
      }
    }
  }

  td {
    > .row {
      margin-top: calc(var(--block-spacing) / 2);
      padding-left: calc(var(--table-inner-padding-h) + var(--wcag-aa-min-size));

      .panel-inner .panel-title {
        border-top: 0;
      }
    }

    .btn,
    .btn-group {
      margin-bottom: 0;
      margin-top: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    .btn-xs {
      &.btn-simple {
        margin: 0;
        padding: 0;
      }

      &.btn-badge {
        min-height: 18px;
        padding-bottom: 1px;
        padding-top: 1px;
      }
    }

    .btn-group.dropdown {
      display: flex;
      min-height: var(--wcag-aa-min-size);
    }

    a {
      align-items: center;
      display: inline-flex;
      min-height: var(--wcag-aa-min-size);
    }
  }

  &.tiny-inputs {
    .form-control {
      padding: 0;
      height: auto;
      width: 100px;
    }
  }
}

.nested-array-row {
  border-left: 1px dashed darkgray;
  margin-left: 4px;
  margin-top: -4px;
  padding-left: 30px;
  width: calc(100% + 4px);
}

.table-component {
  &[data-overflow-x="false"] {
    .table-wrapper {
      overflow-x: initial;
    }
  }

  &[data-overflow-x="true"]:has([data-dropdown-is-open="true"]) {
    .table-wrapper {
      overflow-x: clip;
    }
  }
}

.table-wrapper {
  border: 0;
}

.selected-row {
  background-color: var(--success-background-color) !important;
}

.form-table {
  td {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
}

.card-table {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 8px;
}

.card-table-col {
  border-radius: var(--border-radius-base);
  display: flex;
  flex-direction: column;
}

.card-table-header {
  align-items: center;
  background-color: white;
  display: flex;
  margin-bottom: 8px;
  padding: 4px 8px;
}

.card-table-content {
  background: var(--color-gray-lightest);
  flex-grow: 1;
  max-height: calc(100vh - calc(var(--app-controls-height) + calc(4 * var(--block-spacing)) + var(--stats-card-min-height) + 160px));
  min-width: 240px;
  overflow-y: scroll;
  width: 240px;
}

.card-table-cell {
  background: var(--color-white);
  border: var(--card-border);
  border-radius: var(--border-radius-base);
  box-shadow: var(--card-box-shadow);
  cursor: pointer;
  padding: 8px 12px;
  margin: 4px 4px 8px;
  word-break: break-word;

  &:hover {
    outline: solid var(--info-color);
  }

  &:last-child {
    border-bottom: 0;
  }
}

.table-cell-nowrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination {
  --pagination-round-size: 34px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: var(--block-spacing);

  .pagination-btn {
    align-items: center;
    border: 1px solid var(--color-gray-lighter);
    border-radius: 50%;
    color: var(--color-black);
    cursor: pointer;
    display: flex;
    height: var(--pagination-round-size);
    justify-content: center;
    margin: 2px;
    min-height: var(--pagination-round-size);
    min-width: var(--pagination-round-size);
    padding: 0;
    width: var(--pagination-round-size);

    &:hover {
      background-color: var(--color-gray-lighter);
    }

    &:focus.active,
    &.active {
      background-color: var(--primary-color);
      border: 0;
      color: var(--color-white);
    }

    &.disabled {
      color: var(--muted-color);
    }
  }
}

.kanban-view-icon {
  display: none;
}

[data-is-kanban-view="true"] .kanban-view-icon {
  display: initial;
}

@media (max-width: $screen-sm) {
  .table-wrapper {
    margin-bottom: 0;
    overflow-x: scroll;
  }
}