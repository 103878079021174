// Asset image upload
.upload-image {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 330px;
  background-color: var(--color-gray-lighter);
  opacity: 0.75;
  position: relative;
  cursor: pointer;
}

.asset-img {
  &.active {
    display: flex;
  }

  .carousel-caption {
    padding-top: 0;
    top: 0;
  }

  .asset-img-loader {
    align-items: center;
    background: var(--overlay-background);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
  }
}

// Ace editor diff
.codeMarker {
  background: #fff677;
  position: absolute;
  z-index: 20;
}

.progress-bar {
  background-color: var(--color-gray-lighter);
  border-radius: var(--border-radius-base);
  box-shadow: none;
  float: unset;
  height: 20px;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  width: 100%;

  .progress-bar-filler {
    background-color: var(--primary-color);
    box-shadow: none;
    height: 100%;
  }

  .progress-bar-text {
    align-items: center;
    display: flex;
    font-size: 1.2rem;
    height: 100%;
    line-height: 1;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}

// bootstrap (bt) progress bar
// the top class is "progress" which is too short and enough representative
.progress-bar-bt {
  background-color: var(--color-gray-lighter);
  border-radius: var(--border-radius-base);
  box-shadow: none;

  .progress-bar {
    background-color: var(--muted-color);
  }
}

.tenant-page {
  .tenant-logo img {
    background-color: #e9e9e9;
    margin-bottom: 10px;
  }
}

.preferred-manufacturer-img {
  background-color: white;
  height: 40px;
}

.au1-asset-img {
  max-height: 300px;
  max-width: 100%;
  width: auto;
}

.viewer-profiles-permissions {
  overflow: auto;
  max-height: 810px;

  table,
  th,
  td {
    border: 1px solid var(--color-gray-lighter);
  }

  table {
    width: 100%;
  }

  td {
    padding: 0 2px;

    &:first-child {
      white-space: nowrap;
    }

    &:not(:first-child) {
      min-width: 55px;
      text-align: center;
    }
  }
}

// For accessibility reasons, we cannot use the max-height: 0 technique
// because it would allow focusing the "hidden" form inputs when using tab to navigate.
.advanced-search {
  animation: card-enter 0.55s forwards;
  display: none;

  &[data-show="true"] {
    display: block;
  }
}

.settings-panel-gutter {
  position: relative;
  min-width: 1.5px;
  width: 1.5px;

  &:hover .focus-gutter {
    background-color: var(--color-black-lighter);
    border-left: 1px solid var(--color-white);
    border-right: 1px solid var(--color-white);
    cursor: ew-resize;
    height: 100%;
    left: -3px;
    position: absolute;
    width: 6px;
    z-index: 10;
  }
}

.setting-editor-tabs {
  background: var(--color-white);
  display: flex;
  flex-wrap: wrap;
}

.setting-editor-tab {
  background: var(--color-gray-lighter);
  border: 1px solid var(--color-gray-lighter);
  border-radius: var(--border-radius-base);
  color: var(--color-gray-darkest);
  cursor: pointer;
  font-size: var(--nav-controls-category-font-size);
  margin: 1px;
  padding: 0 5px;
  transition: all 0.1s;

  .tab-name {
    display: inline-block;
    padding: 5px 0;
  }

  .setting-editor-tab-close {
    border-radius: var(--border-radius-base);
    pointer-events: none;
    margin-left: 3px;
    padding: 3px;
    visibility: hidden;

    &:hover {
      background: var(--color-gray);
    }
  }

  &:hover {
    background: var(--color-gray-lightest);
  }

  &[data-is-selected="true"] {
    background: var(--color-white);
    color: var(--text-default);
  }

  &:hover,
  &[data-is-selected="true"] {
    .setting-editor-tab-close {
      pointer-events: all;
      visibility: visible;
    }
  }
}

.settings-panel-wrapper {
  height: 100%;
}

@media (max-width: $screen-xs) {
  .scripts-page {
    flex-direction: column;
    height: auto !important;
  }

  .settings-list {
    max-height: 80vh;
  }

  .settings-panel-gutter {
    display: none;
  }

  .settings-panel-wrapper {
    height: auto;
    max-height: 100%;
    min-width: 100%;
  }

  .viewer-script {
    min-height: 100vh;
  }
}

.card-control-btn {
  --right-offset-base: calc(var(--block-spacing) + var(--scrollbar-browser-width));
  --right-offset-step: 35px;
  align-items: center;
  background-color: var(--primary-color);
  bottom: 20px;
  border-radius: 100%;
  border: 1px solid var(--color-white);
  color: var(--color-white);
  cursor: pointer;
  display: flex;
  font-size: var(--control-btn-font-size);
  height: var(--control-btn-hw);
  justify-content: center;
  position: fixed;
  right: var(--right-offset-base);
  width: var(--control-btn-hw);
  z-index: 100;

  &[data-offsets="1"] {
    right: calc(var(--right-offset-base) + var(--right-offset-step));
  }
}

[data-show-side-column="false"] {
  .left-col {
    width: 100%;
  }

  .right-col {
    display: none;
  }

  .card-control-btn {
    transform: rotateY(180deg);
  }
}

.tile-btn {
  border: 1px solid var(--color-gray-lighter);
  border-radius: var(--border-radius-base);
  cursor: pointer;
  min-height: calc(100% - var(--block-spacing));
  position: relative;
  text-align: center;

  &[data-is-selected="true"] {
    border-color: var(--primary-color);

    &:focus {
      background-color: var(--color-white);
      border-color: var(--color-gray-lighter);
    }

    .tile-selected-hint {
      display: block;
    }

    .tile-inner {
      border-color: var(--primary-color);
    }
  }

  &:focus,
  &:hover {
    background-color: var(--color-gray-lightest);
    border-color: var(--input-border-focus-color);
  }

  .tile-selected-hint {
    --tile-hint-size: 36px;
    display: none;
    height: var(--tile-hint-size);
    position: absolute;
    top: 0;
    right: 0;
    width: var(--tile-hint-size);

    i {
      margin-left: 12px;
    }

    &::before {
      border-left: var(--tile-hint-size) solid transparent;
      border-top: var(--tile-hint-size) solid var(--primary-color);
      content: "";
      display: block;
      height: 0px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 0px;
    }
  }

  .tile-inner {
    border: 2px solid transparent;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 5px;
  }
}

.chat-picture-wrapper {
  border: 1px solid var(--color-gray);
  border-radius: var(--border-radius-base);
  cursor: zoom-in;
  display: inline-block;
  overflow: hidden;
  position: relative;

  img {
    height: 80px;
    object-fit: cover;
    width: 130px;
  }

  .remove-picture-btn {
    align-items: center;
    background-color: var(--color-gray-darkest);
    border: 1px solid var(--color-gray);
    border-radius: 50%;
    color: var(--color-white);
    cursor: pointer;
    display: flex;
    height: 20px;
    justify-content: center;
    opacity: 0;
    position: absolute;
    right: 1px;
    top: 1px;
    width: 20px;
    z-index: 1;
  }

  &:hover {
    .remove-picture-btn {
      opacity: 1;
    }
  }
}