.authentication-layout {
  background: var(--authentication-background);
  position: relative;
  transition: opacity 0.05s ease-in;

  &::before {
    background: var(--authentication-overlay-background);
    content: "";
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}

.authentication {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  z-index: 2;

  &.login-page,
  &.mfa-verification-page,
  &.mfa-initialization-page,
  &.change-password-page {
    form .card {
      background: var(--authentication-form-background-color, white);
      border: 0;
    }
  }

  form {
    margin: 0 auto;
    max-width: 340px;
    width: 100%;

    .card {
      margin-bottom: 0;

      .header {
        background: transparent;
        // padding-bottom: 0;
      }

      .login-title {
        font-size: 3.6rem;
        font-weight: bold;
        line-height: 36px;
        margin: var(--block-spacing) 0 0;
      }

      .btn-wd {
        // necessary for external auth buttons
        min-height: 38px;
        min-width: 180px;
      }
    }
  }

  .text-link {
    color: var(--color-blue);
    cursor: pointer;
    font-size: var(--font-size-base);
    padding: 0;
  }

  .card .content {
    padding: var(--card-content-padding);
  }
}

.terms-of-service-page {
  display: flex;
  justify-content: center;

  .terms-of-service-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 100vw;
  }

  .terms-of-service-viewer {
    background: var(--color-white);
    border: 0;
    border-radius: var(--border-radius-base);
    height: 100%;
    margin-top: var(--block-spacing);
    max-height: 800px;
    max-width: 50%;
    min-width: 50vw;
    overflow-y: auto;
    width: 100%;
  }

  .decision-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .decision-button {
    margin: var(--btns-margin);
  }
}

.login-page,
.change-password-page {
  .card {
    animation: unset;

    .header {
      background: transparent;
    }

    .footer {
      padding-top: 0;
    }
  }

  .title img {
    height: 60px;
    max-width: 100%;
    object-fit: contain;
  }
}

.mfa-verification-component,
.mfa-verification-page,
.mfa-initialization-page {
  h4 {
    font-weight: bold;
    margin-top: 0;
  }

  .header {
    padding-bottom: 0;
  }

  p {
    font-size: var(--font-size-base);
  }

  img {
    display: block;
    height: 60px;
    max-width: 100%;
    object-fit: contain;
    margin: 0 auto;
  }
}

.mfa-verification-component {
  .mfa-inputs {
    display: flex;
    margin: 10px auto;
    justify-content: center;
    padding: 1px 0;

    >div {
      position: relative;

      i {
        font-size: 2rem;
        line-height: 20px;
        position: absolute;
        right: -23px;
        top: 13px;
      }
    }

    input {
      background-color: rgb(248, 248, 248);
      border-radius: 2px;
      border: 1px solid var(--color-gray);
      font-size: 2rem;
      height: 46px;
      margin: 0 3px;
      text-align: center;
      width: 20px;

      &:focus {
        outline: 2px solid var(--primary-color);
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .terms-of-service-page {
    .terms-of-service-viewer-md {
      max-width: 75%;
    }
  }
}

@media (max-width: $screen-xs) {
  .authentication {

    &.login-page,
    &.change-password-page,
    &.mfa-verification-page,
    &.mfa-initialization-page {
      background: white;
      background-image: none !important;
      justify-content: flex-start;

      .card {
        animation: none;
        box-shadow: unset;
      }
    }
  }

  .terms-of-service-page {
    .terms-of-service-viewer {
      border-radius: 0;
      margin-top: 0;
      max-width: unset;
    }
  }
}